import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {  OrderDetailsService, OrderDetailTotalsComponent, OrderOverviewComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-order-confirmation-overview',
  templateUrl: './kn-br-order-confirmation-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrOrderConfirmationOverviewComponent extends OrderDetailTotalsComponent implements OnInit {
  constructor(protected orderDetailsService: OrderDetailsService) {
    super(orderDetailsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // ngOnDestroy() {
  //   super.ngOnDestroy();
  // }
}
