import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  imports: [
    I18nModule,
    ConfigModule.withConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: {
          knbrCommons: ['button', 'saved', 'label', 'knBrMessages', 'toolTip', 'myAccountPriceList', 'httpHandlers'],
          knbrHome: ['counter', 'customer', 'quicklinks'],
          knbrCart: ['cartCommon', 'create', 'cartSearchForm', 'cartResult', 'addToCartMessage'],
          knbrQuote: [
            'quoteCommon',
            'quoteSearchForm',
            'quoteResult',
            'actionBar',
            'quoteDetailHeader',
            'quoteItems',
            'quotePartialItems',
            'addToQuoteMessage',
            'quoteReference',
          ],
          knbrInvoice: ['invoiceSearchForm', 'invoiceResult', 'knbrAccountSummary', 'knbrInvoiceDetails'],
          knbrCaseCreate: ['caseCreationForm'],
          knbrOrder: [
            'orderHistorySearchForm',
            'orderHistoryResult',
            'knbrOrderDetails',
            'knBrOrderReturn',
            'knbrCancelOrder',
            'orderReturnResult',
            'orderReturnsSearchForm',
            'knbrReturnOrderDetails',
            'freightPopUp',
          ],
          address: ['addressForm', 'addressBook', 'addressCard'],
          payment: ['paymentForm', 'paymentMethods', 'paymentCard', 'paymentTypes'],
          myAccount: [
            'orderDetails',
            'orderHistory',
            'closeAccount',
            'updateEmailForm',
            'updatePasswordForm',
            'updateProfileForm',
            'consentManagementForm',
            'myCoupons',
            'wishlist',
            'notificationPreference',
            'myInterests',
            'AccountOrderHistoryTabContainer',
            'returnRequestList',
            'returnRequest',
          ],
          checkout: [
            'checkout',
            'checkoutAddress',
            'checkoutOrderConfirmation',
            'checkoutReview',
            'checkoutShipping',
            'checkoutProgress',
            'checkoutPO',
          ],
          knbrOrderTools: ['orderImport', 'quickOrder', 'orderForms'],
          knbrQuickEntry: ['quickEntry'],
          knbrAddress: ['knbrAddressBook', 'knbrAddressForm'],
          knbrServiceTickets: ['serviceTicketsForm'],
          knbrNac: [
            'nacConfigurbaleForm',
            'downloadConfirmationDialog',
            'updateTemplateDialog',
            'uploadNewTemplateDialog',
          ],
          knbrFactSheet: ['factSheetHeader', 'factSheetGroup', 'factSheetMaterial', 'factSheetModal'],
          user: ['anonymousConsents', 'forgottenPassword', 'loginForm', 'register', 'checkoutLogin', 'authMessages', 'KnbrProfile'],
          common: [
          'common',
          'pageMetaResolver',
          'spinner',
          'navigation',
          'searchBox',
          'sorting',
          'httpHandlers',
          'miniCart',
          'miniLogin',
          'skipLink',
          'formErrors',
          'errorHandlers',
          'chatMessaging',
        ],
        authMessages: ['authMessages'],
        knbrShipment:['shipmentSearchForm','shipmentSearchResult'],
        },
        fallbackLang: 'en',
      },
    }),
  ],
})
export class KnBrI18nModule {}
