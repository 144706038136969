<ng-container>
  <div class="col-md-12 p-0 pb-4 float-right d-flex justify-content-between">
    <h3 class="mt-2 font-text-600 titleName">Shipment Delivery/Listing</h3>
  </div>
</ng-container>
<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms mb-4">
  <form [formGroup]="shipmentSearchForm" (ngSubmit)="submitForm()">
    <div class="form-group col-lg-6 pl-0 multi-select-styles">
      <ng-select
        *ngIf="!hasContext"
        [items]="customersDataList$ | async"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.customersId' | cxTranslate"
        clearAllText="Clear"
        formControlName="accountNumber"
        [bindLabel]="'Text'"
        class="hideplaceholder"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['Text'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['Text'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <input *ngIf="hasContext"
        type="text"
        class="form-control w-100"
        [placeholder]="'shipmentSearchForm.label.customersId' | cxTranslate"
        formControlName="accountNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.customersId' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="'shipmentSearchForm.label.salesOrderNumber' | cxTranslate" formControlName="salesOrderNumber"/>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.salesOrderNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="'shipmentSearchForm.label.purchaseOrderNumber' | cxTranslate" formControlName="purchaseOrderNumber"/>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.purchaseOrderNumber' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select
        [items]="shipmentTypeList$ | async"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.shippmentType' | cxTranslate"
        clearAllText="Clear"
        formControlName="shippingType"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.shippmentType' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles pb-1">
      <ng-select
        [items]="shipmentStatusList$ | async"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.status' | cxTranslate"
        clearAllText="Clear"
        formControlName="shippingStatus"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.status' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="'shipmentSearchForm.label.productNumber' | cxTranslate" formControlName="product"/>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.productNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="'shipmentSearchForm.label.productName' | cxTranslate" formControlName="productName"/>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.productName' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-2 p-res-0 valid-to">
      <div class="input-group">
        <input
          class="form-control w-100 mb-0 dateControls"
          [placeholder]="'shipmentSearchForm.label.from' | cxTranslate"
          name="fromDate"
          ngbDatepicker
          #fromDate="ngbDatepicker"
          formControlName="_fromDate"
          [readonly]="true"
          [minDate]="this.minDates ? this.minDates : ''"
          [maxDate]="this.maxdatefrom ? this.maxdatefrom : ''"
          (dateSelect)="onDateChange($event, 'fDate')"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'shipmentSearchForm.label.from' | cxTranslate }}</label>
        <div class="input-group-append">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="fromDate.toggle()" type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
        </div>
      </div>
      <div *ngIf="shipmentSearchForm.controls['_fromDate'].errors as errors" class="fromDate">
        Please select from date
      </div>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-2 p-res-0 valid-to">
      <div class="input-group">
        <input
          class="form-control w-100 mb-0 dateControls"
          [placeholder]="'shipmentSearchForm.label.to' | cxTranslate"
          name="toDate"
          ngbDatepicker
          #toDate="ngbDatepicker"
          formControlName="_toDate"
          [readonly]="true"
          [minDate]="this.mindateTo ? this.mindateTo : ''"
          [maxDate]="this.maxDates ? this.maxDates : ''"
          (dateSelect)="onDateChange($event, 'tDate')"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'shipmentSearchForm.label.to' | cxTranslate }}</label>
        <div class="input-group-append">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="toDate.toggle()" type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
        </div>
      </div>
      <div *ngIf="shipmentSearchForm.controls['_toDate'].errors" class="toDate">
        Please select to date
      </div>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        [items]="items.brand"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.brand' | cxTranslate"
        clearAllText="Clear"
        formControlName="brand"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.brand' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        [items]="items.shipTo"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.shipTo' | cxTranslate"
        clearAllText="Clear"
        formControlName="shipToCity"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.shipTo' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        [items]="items.salesGroup"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.salesGroup' | cxTranslate"
        clearAllText="Clear"
        formControlName="salesGroup"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.salesGroup' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        [items]="items.salesOffice"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        labelForId="state"
        [multiple]="true"
        [placeholder]="'shipmentSearchForm.label.salesOffice' | cxTranslate"
        clearAllText="Clear"
        formControlName="salesOffice"
        [bindLabel]="'name'"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
            {{ item['name'] }}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
            {{ item['name'] }}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{ items.length - 1 }} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'shipmentSearchForm.label.salesOffice' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 mt-3 p-0 float-left">
      <div class="form-group col-lg-2 pl-0 float-left mb-3 p-res-0">
        <input type="text" class="form-control w-100" [placeholder]="'shipmentSearchForm.label.jobPoNumber' | cxTranslate" formControlName="jobName"/>
        <span class="highlight"></span>
        <label class="text-muted">{{ 'shipmentSearchForm.label.jobName' | cxTranslate }}</label>
      </div>

      <div class="form-group col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles">
        <ng-select
          [items]="shipmentPointList$ | async"
          [clearSearchOnAdd]="true"
          [closeOnSelect]="false"
          labelForId="state"
          [multiple]="true"
          [placeholder]="'shipmentSearchForm.label.shippingPoint' | cxTranslate"
          clearAllText="Clear"
          formControlName="shippingPoint"
          [bindLabel]="'name'"
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="row">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"/>
              {{ item['name'] }}
            </div>
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items">
            <div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
              {{ item['name'] }}
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length - 1 }} more...</span>
            </div>
          </ng-template>
        </ng-select>
        <span class="highlight"></span>
        <label class="text-muted">{{ 'shipmentSearchForm.label.shippingPoint' | cxTranslate }}</label>
      </div>
    </div>

    <div class="col-lg-12 mt-3 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="submit" class="w-100 btn btn-primary">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
