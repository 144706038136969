import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KnBrShippingComponent } from './kn-br-shipping.component';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [KnBrShippingComponent],
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    NgbModule,
    FormErrorsModule,
    NgSelectModule,
    NgbTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleShippingListHeaderComponent: {
          component: KnBrShippingComponent
        }
      }
    } as CmsConfig)
  ]
})
export class KnBrShippingModule { }
