import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrShippingListComponent } from './kn-br-shipping-list.component';



@NgModule({
  declarations: [KnBrShippingListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    NgbModule,
    SpinnerModule,
    FormErrorsModule,
    NgSelectModule,
    NgbTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleShippingListBodyComponent: {
          component: KnBrShippingListComponent
        }
      }
    } as CmsConfig)
  ]
})
export class KnBrShippingListModule { }
