import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash';
import { Observable, Subscription, zip } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CustomerData } from 'src/app/models/customer.model';
import { Statuses } from 'src/app/models/kn-br-draft-order.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/app/store/kn-br-order-history/kn-br-order-history.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Component({
  selector: 'knbr-kn-br-order-history-search',
  templateUrl: './kn-br-order-history-search.component.html',
  styleUrls: ['./kn-br-order-history-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrOrderHistorySearchComponent implements OnInit {
  orderHistorySearchForm: UntypedFormGroup;
  orderStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadOrderStatusList$();
  brandSalesShipToList$: Observable<Statuses[]> = this.knBrCommonService.loadBrandSalesShipToList$();
  creditStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadCreditStatusList$();
  orderTypeList$: Observable<Statuses[]> = this.knBrCommonService.loadOrderTypeList$();
  deliveryBlockList$: Observable<Statuses[]> = this.knBrCommonService.loadDeliveryBlockList$();
  customersDataList$: Observable<any> = this.knBrCommonService.loadCustomersData$();
  emptyList: Statuses[] = [];
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;
  currentPage = 0;
  isSearchDisabled$: Observable<boolean> = this.knBrOrderHistoryService.isSearchDisabled$;
  modalRef: any;
  fromDate: any;
  toDate: any;
  hasDates = false;
  pOrderType = [];
  pDeliveryBlock = [];
  pStatus = [];
  pBrand = [];
  pShipTo = [];
  pSalesOffice = [];
  pSalesGroup = [];
  pCreditStatus = [];
  state$: Observable<unknown>;
  combinedCallSubscription: Subscription;
  retrievalPending = true;
  subscription = new Subscription();
  customerNumber = '';
  hasContext = false;
  // customersDataList = [];
  accountNumber = [];
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  maxEndDate = () => this.todayDate();
  minEndDate = () => this.todayDate();

  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    private knBrDateHelper: KnBrDateHelper,
    protected activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state));

    this.subscription.add(
      this.customerContextData$.subscribe((value) => {
        if (value) {
          this.hasContext = true;
          this.selectedCustomer = value;
          this.customerNumber = this.selectedCustomer.customerNumber ? this.selectedCustomer.customerNumber : null;
        };
        const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
        this.knBrCommonService.getOrderStatusList(customerNumber);
        this.knBrCommonService.getCreditStatusList(customerNumber);
        this.knBrCommonService.getOrderTypeList(customerNumber);
        this.knBrCommonService.getDeliveryBlockList(customerNumber);
        this.knBrCommonService.getBrandSalesShipToList(customerNumber);

        this.initForm();
      })
    )
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        // this.submitForm("persist");
      } else {
        this.submitForm()
      }
    })
    // this.subscription.add(
    //   this.knBrCommonService.customersData$.subscribe(res => {
    //     if (res) {
          // this.customersDataList = this.removeDuplicates(res);
    //     } else {
    //       this.knBrCommonService.getCustomersData();
    //     }
    //   })
    // );
  }

  removeDuplicates(res) {
    return Array.from(new Set(res));
  }

  initForm() {
    const persistedData = JSON.parse(sessionStorage.getItem("orderHistorySearch"));
    let flagValue = false;
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        flagValue = true;
      } else {
        flagValue = false;
      }
    });
    this.orderHistorySearchForm = this.fb.group({
      salesOrderNumber: (flagValue && persistedData?.salesOrderNumber) ? persistedData.salesOrderNumber : null,
      accountNumber: [{
        value: this.selectedCustomer && this.selectedCustomer.customerNumber ? this.knBrCommonService.combineCustomerData(this.selectedCustomer) : null,
        disabled: this.selectedCustomer ? true : false
      }],
      purchaseOrderNumber: (flagValue && persistedData?.purchaseOrderNumber) ? persistedData.purchaseOrderNumber : null,
      orderType: null,
      creditStatus: null,
      deliveryBlock: null,
      status: null,
      productNumber: (flagValue && persistedData?.productNumber) ? persistedData.productNumber : null,
      currentPage: this.currentPage,
      productName: (flagValue && persistedData?.productName) ? persistedData.productName : null,
      startDate: null,
      endDate: null,
      _startDate: (flagValue && persistedData?.startDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData.startDate)) : null,
      _endDate: (flagValue && persistedData?.endDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData.endDate)): null,
      brand: null,
      shipTo: null,
      salesOffice: null,
      salesGroup: null,
      jobName: (flagValue && persistedData?.jobName) ? persistedData.jobName : null,
      pageSize: 50,
    });
    this.setDateValidators();

    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues && this.retrievalPending) {
        if(persistedData) {

          const sessionStorageCusNo = sessionStorage.getItem("sscustomerNumber");
          if(this.selectedCustomer.customerNumber != sessionStorageCusNo && this.retrievalPending) {
            this.retrievalPending = false;
            this.combinedCallSubscription = zip(this.orderStatusList$, this.orderTypeList$, this.deliveryBlockList$, this.creditStatusList$, this.brandSalesShipToList$).subscribe((res:any)=> {
              const combinedData = {
                statusData: res[0],
                orderTypeData: res[1],
                deliveryBlockData: res[2],
                creditStatusData: res[3],
                bresp: res[4],
              }
              const {deliveryBlockData, statusData, orderTypeData, creditStatusData, bresp } = combinedData;
                if(persistedData.orderType) {
                  persistedData.orderType = persistedData.orderType.filter(eachValidity => this.containsObject(eachValidity, orderTypeData));
                  this.pOrderType = persistedData.orderType;
                }
                if(persistedData.deliveryBlock) {
                  persistedData.deliveryBlock = persistedData.deliveryBlock.filter(eachDeliveryBlock => this.containsObject(eachDeliveryBlock, deliveryBlockData));
                  this.pDeliveryBlock = persistedData.deliveryBlock;
                }
                if(persistedData.status) {
                  persistedData.status = persistedData.status.filter(eachStatus => this.containsObject(eachStatus, statusData));
                  this.pStatus = persistedData.status;
                }
                if(persistedData.brand) {
                  persistedData.brand = persistedData.brand.filter((eachbrnd) => this.containsObject(eachbrnd, bresp.brand) )
                  this.pBrand = persistedData.brand || [];
                }
                if(persistedData.shipTo){
                  persistedData.shipTo = persistedData.shipTo.filter(eachShipTo => this.containsObject(eachShipTo, bresp.shipTo));
                  this.pShipTo = persistedData.shipTo;
                }
                if(persistedData.salesOffice) {
                  persistedData.salesOffice = persistedData.salesOffice.filter((eachSalesOffice)=> this.containsObject(eachSalesOffice, bresp.salesOffice));
                  this.pSalesOffice = persistedData.salesOffice;
                }
                if(persistedData.salesGroup) {
                  persistedData.salesGroup = persistedData.salesGroup.filter((eachgrp)=> this.containsObject(eachgrp,bresp.salesGroup))
                  this.pSalesGroup = persistedData.salesGroup;
                }
                if(persistedData.creditStatus) {
                  persistedData.creditStatus = persistedData.creditStatus.filter(eachCreditStatus => this.containsObject(eachCreditStatus, creditStatusData))
                  this.pCreditStatus = persistedData.creditStatus;
                }
                this.submitForm("persist")
            })
          }  else if(this.retrievalPending) {
            this.pOrderType = persistedData.orderType || [];
            this.pDeliveryBlock = persistedData.deliveryBlock || [];
            this.pStatus = persistedData.status || [];
            this.pBrand = persistedData.brand || [];
            this.pShipTo = persistedData.shipTo || [];
            this.pSalesOffice = persistedData.salesOffice || [];
            this.pSalesGroup = persistedData.salesGroup || [];
            this.pCreditStatus = persistedData.creditStatus || [];
            this.submitForm("newpersist")
          }
        }
      }
    })

  }

  setDateValidators() {
    const startDate = this.orderHistorySearchForm.get('_startDate');
    const enddate = this.orderHistorySearchForm.get('_endDate');

    startDate.valueChanges.pipe(distinctUntilChanged()).subscribe(fromDate => {
      if (fromDate && enddate.value === null) {
        enddate.setValidators([Validators.required]);
      } else {
        enddate.setValidators(null);
      }
      enddate.updateValueAndValidity();

      if (enddate.value && startDate.value) {
        const { year: y2, month: m2, day: d2 } = enddate.value;
        const e = new Date(y2, m2 - 1, d2);

        const { year: y1, month: m1, day: d1 } = startDate.value;
        const s = new Date(y1, m1 - 1, d1);

        if (s > e) {
          enddate.setValue(null)
        }
      }
    });
    enddate.valueChanges.pipe(distinctUntilChanged()).subscribe(toDate => {
      if (toDate) {
        startDate.setValidators([Validators.required]);
      } else {
        startDate.setValidators(null);
      }
      startDate.updateValueAndValidity();
    });
  }

  submitForm(shouldRetain?) {
    if (this.orderHistorySearchForm.valid) {
      this.orderHistorySearchForm.patchValue({
        startDate: this.knBrDateHelper.formatDate(this.orderHistorySearchForm.value._startDate),
        endDate: this.knBrDateHelper.formatDate(this.orderHistorySearchForm.value._endDate)
      });

      const { _startDate, _endDate, ...payload } = this.orderHistorySearchForm.getRawValue();
      const updatedPayload = {...payload};
      const updatedSessionStorage = {...payload};


      if(shouldRetain) {
        updatedSessionStorage.orderType = this.pOrderType ;
        updatedSessionStorage.deliveryBlock = this.pDeliveryBlock ;
        updatedSessionStorage.status = this.pStatus ;
        updatedSessionStorage.brand = this.pBrand ;
        updatedSessionStorage.shipTo = this.pShipTo ;
        updatedSessionStorage.salesOffice = this.pSalesOffice ;
        updatedSessionStorage.salesGroup = this.pSalesGroup ;
        updatedSessionStorage.creditStatus = this.pCreditStatus ;
        updatedPayload.accountNumber = this.selectedCustomer?.customerNumber;
        updatedPayload.orderType = (this.pOrderType && this.pOrderType.length) ? this.pOrderType.map(eachOrderType => eachOrderType.Key).join(",") : null;
        updatedPayload.deliveryBlock = (this.pDeliveryBlock && this.pDeliveryBlock.length) ? this.pDeliveryBlock.map(eachDeliveryBlock => eachDeliveryBlock.Key).join(","): null;
        updatedPayload.status = (this.pStatus && this.pStatus.length) ? this.pStatus.map(eachStatus => eachStatus.Key).join(","): null;
        updatedPayload.brand = (this.pBrand && this.pBrand.length) ? this.pBrand.map(eachBrand => eachBrand.value).join(",") : null;
        updatedPayload.shipTo = (this.pShipTo && this.pShipTo.length) ? this.pShipTo.map(eachShipTo => eachShipTo.value).join(",") :null;
        updatedPayload.salesOffice = (this.pSalesOffice &&  this.pSalesOffice.length) ? this.pSalesOffice.map(eachSalesOffice => eachSalesOffice.value).join(",") : null;
        updatedPayload.salesGroup = (this.pSalesGroup &&  this.pSalesGroup.length) ? this.pSalesGroup.map(eachSalesGroup => eachSalesGroup.value).join(",") : null;
        updatedPayload.creditStatus = (this.pCreditStatus &&  this.pCreditStatus.length) ? this.pCreditStatus.map(eachCreditStatus => eachCreditStatus.Key).join(",") : null;

      } else {
        updatedPayload.accountNumber = (payload.accountNumber && Array.isArray(payload.accountNumber) && payload.accountNumber.length) ? payload.accountNumber.map(eachacc => eachacc.Key).join(",") : this.selectedCustomer?.customerNumber;
        updatedPayload.orderType = (payload.orderType && payload.orderType.length) ? payload.orderType.map(eachOrderType => eachOrderType.Key).join(",") : null;
        updatedPayload.deliveryBlock = (payload.deliveryBlock && payload.deliveryBlock.length) ? payload.deliveryBlock.map(eachDeliveryBlock => eachDeliveryBlock.Key).join(","): null;
        updatedPayload.status = (payload.status && payload.status.length) ? payload.status.map(eachStatus => eachStatus.Key).join(","): null;
        updatedPayload.brand = (payload.brand && payload.brand.length) ? payload.brand.map(eachBrand => eachBrand.value).join(",") : null;
        updatedPayload.shipTo = (payload.shipTo && payload.shipTo.length) ? payload.shipTo.map(eachShipTo => eachShipTo.value).join(",") :null;
        updatedPayload.salesOffice = (payload.salesOffice &&  payload.salesOffice.length) ? payload.salesOffice.map(eachSalesOffice => eachSalesOffice.value).join(",") : null;
        updatedPayload.salesGroup = (payload.salesGroup &&  payload.salesGroup.length) ? payload.salesGroup.map(eachSalesGroup => eachSalesGroup.value).join(",") : null;
        updatedPayload.creditStatus = (payload.creditStatus &&  payload.creditStatus.length) ? payload.creditStatus.map(eachCreditStatus => eachCreditStatus.Key).join(",") : null;
      }
      window.sessionStorage.setItem("orderHistorySearch",JSON.stringify(updatedSessionStorage));
      window.sessionStorage.setItem("sscustomerNumber", this.selectedCustomer?.customerNumber);
      this.knBrOrderHistoryService.search(updatedPayload);
    }
  }

  onDateChange(value, param?) {
    this.knBrCommonService.onDateChange(value, param);
  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (isEqual(list[i], obj)) {
            return true;
        }
    }

    return false;
}

  resetForm() {
    this.orderHistorySearchForm.reset();
    sessionStorage.removeItem('orderHistorySearch');
    this.initForm();
    this.submitForm();
    this.clearDateInfo();
  }

  clearDateInfo() {
    this.knBrCommonService.clearDateInfo();
  }

  ngOnDestroy() {
    if(this.combinedCallSubscription) {
      this.combinedCallSubscription.unsubscribe();
    }
    this.clearDateInfo();
    this.subscription?.unsubscribe();
  }
}
