import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { KnBrMessageDialogService } from 'src/app/common/kn-br-message-dialog/kn-br-message-dialog.service';
import { KnBrContactService } from 'src/app/services/kn-br-contact.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { pick } from 'lodash';
import { usaStateNames, canadaStateNames } from 'src/constants/stateNames.constant';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'knbr-kn-br-contact',
  templateUrl: './kn-br-contact.component.html',
  styleUrls: ['./kn-br-contact.component.scss'],
})
export class KnBrContactComponent implements OnInit, OnDestroy {
  userInfo = { name: '', email: '' , company:''};
  countries = {};
  states = {};
  contactReasons = [
    'I do not see my Location(s)',
    'I do not see past Quotes',
    'I do not see past Orders or Invoices',
    'I do not see products I order on the Product Listing Page',
    'I can not download my Price Book to email to me',
    'I see an incorrect price in my Price Book',
    'I have a question on the Delivery Date for a Delivery/Shipment',
    'I can not find a Product Data Sheet or Safety Data Sheet',
    'I’d like to remove a user from my business from the Customer Success Portal',
    'I’d like to make a change on a user from my business for the Customer Success',
    'I would like to Sign-up Other Users from my business for the Customer Success Portal',
    'My profile details are incorrect',
    'I have a warranty related question',
    'Something else',
  ];
  brands = {
    "US" : ["Carlisle SynTec", "Business Partner", "Versico", "WeatherBond", "Hunter Panels", "Hunter Xci", "Hunter Business Partner", "Hunter Xci Business Partner"],
    "CA"  : ["Carlisle SynTec", "Hunter Panels",  "Hunter Xci", "Hunter Business Partner"]
  }
  objectKeys = Object.keys;
  contactUsForm!: FormGroup;
  selectedBrands = [];
  showSpinner: void | Observable<ComponentRef<any>>;
  submitted = false;
  state$: Observable<unknown>;
  subscription = new Subscription();

  constructor(
    private knBrContactService: KnBrContactService,
    private customerContextService: KnBrCustomerContextService,
    protected globalMessageService: GlobalMessageService,
    protected knBrMessageDialogService: KnBrMessageDialogService,
    protected launchDialogService: LaunchDialogService,
    private translation: TranslationService,
    protected vcr: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    protected activatedRoute: ActivatedRoute,
  ) {
    this.contactUsForm = this.formBuilder.group({
      name: new FormControl({value: '', disabled: true }, [Validators.required]),
      emailId: new FormControl({value: '', disabled: true }, [Validators.required]),
      company: new FormControl('', [Validators.required]),
      phnNo: new FormControl(''),
      country:  new FormControl('', [Validators.required]),
      region:  new FormControl('', [Validators.required]),
      city:  new FormControl('', [Validators.required]),
      contactReason: new FormControl("", [Validators.required]),
      contactSource: new FormControl(''),
      inquiryText: new FormControl('', [Validators.required]),
      brandList: new FormControl(''),
      soldToId: new FormControl(''),
      soldToName: new FormControl(''),
      contactPhone: new FormControl(false),
      contactEmail: new FormControl(false),
    }, {validators : this.customValidationForHowToContact});
  }

  customValidationForHowToContact(formGroup): any {
    let contactPhone = formGroup.controls['contactPhone'].value;
    let contactEmail = formGroup.controls['contactEmail'].value;
    return !(contactPhone || contactEmail) ? { phnemail: true } : null;;
 }

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state));

    this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    forkJoin([this.knBrContactService.getCurrentUserInfo(), this.knBrContactService.getCountries()]).subscribe(
      (res: any) => {
        this.userInfo = res[0];
        this.contactUsForm.get('name').patchValue(this.userInfo.name);
        this.contactUsForm.get('emailId').patchValue(this.userInfo.email);
        this.contactUsForm.get('company').patchValue(this.userInfo.company);
        this.countries = Object.keys(res[1])
          .filter((key) => key.includes('US') || key.includes('CA'))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: res[1][key],
            });
          }, {});
        this.hideLoader();
        this.cdRef.detectChanges();
      }
    );
    this.subscription.add(
      this.state$.subscribe((data:any) => {
        if (data?.fromProfilePage) {
          this.contactUsForm.controls.contactReason.patchValue('My profile details are incorrect')
        }
      })
    )

    this.customerContextService.get$.subscribe((cust) => {
      if(cust) {
        this.contactUsForm.get('soldToId').patchValue(cust.customerNumber);
        this.contactUsForm.get('soldToName').patchValue(cust.customerName);
      }
    });
    this.contactUsForm.get('country').valueChanges.subscribe((val) => {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
      this.knBrContactService.getStates(val).subscribe((states: any) => {
        this.hideLoader();
        const getStateConstants = val === 'US' ? usaStateNames : canadaStateNames;
        this.states = pick(states, getStateConstants);
      });
    });
    this.contactUsForm.get('contactPhone').valueChanges.subscribe((val) => {
      if (val) {
        this.contactUsForm
          .get('phnNo')
          .setValidators([Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]);
        this.contactUsForm.get('phnNo').updateValueAndValidity();
      } else {
        this.contactUsForm.get('phnNo').removeValidators([Validators.required]);
        this.contactUsForm.get('phnNo').updateValueAndValidity();
      }
    });
  }

  hideLoader() {
    if (this.showSpinner) {
      this.showSpinner
        .subscribe((component) => {
          this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
          component.destroy();
        })
        .unsubscribe();
    }
  }

  getBrands(country: string) {
    if(!country && country == '') return null;
    return this.brands[country];
  }

  onBrandChange(brand:string, isChecked: boolean) {
    if(isChecked) {
      if(this.contactUsForm.value.country === 'CA') { brand += ' CA';}
      this.selectedBrands.push(brand);
    } else {
      let index = this.selectedBrands.indexOf(brand);
      this.selectedBrands.splice(index,1);
    }
}

  submit() {
    this.submitted = true;
    if (this.contactUsForm.valid) {
      if(this.selectedBrands.length === 0) {
        return;
      }
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
      const { contactPhone, contactEmail, contactSource, brandList, country, region, ...payload } = this.contactUsForm.getRawValue();
      const _contactSource = [];
      if (contactPhone) {
        _contactSource.push('phone');
      }
      if (contactEmail) {
        _contactSource.push('email');
      }
      let formData: FormData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      formData.append('contactSource', _contactSource.join(','));
      if(this.selectedBrands.length) {
        formData.append('brandList', this.selectedBrands.join(','));
      }
      formData.append('country', this.countries[country] ? this.countries[country] : '');
      formData.append('region', this.states[region] ? this.states[region] : '');
      this.knBrContactService.submitContactForm(formData).subscribe(
        (response: any) => {
          this.hideLoader();
          this.resetForm();
          this.translation.translate('knBrMessages.contactUsSuccessMsg').subscribe(msg => {
            this.knBrMessageDialogService.open('knBrMessages.contactUsSuccessMsgTitle', '', 'button.ok', 'lg', msg);
          });
          this.knBrMessageDialogService.getModalRef().result
              .then(() => {
                window.location.reload();
              });
        },
        (err) => {
          this.hideLoader();
          this.globalMessageService.add({ key: 'knBrMessages.contactUsErrorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
    } else {
      this.contactUsForm.markAllAsTouched();
    }
  }

  resetForm() {
    const name = this.contactUsForm.value.name;
    const email = this.contactUsForm.value.emailId;
    const company = this.contactUsForm.value.company;
    this.contactUsForm.reset();
    this.contactUsForm.get('name').patchValue(name);
    this.contactUsForm.get('emailId').patchValue(email);
    this.contactUsForm.get('company').patchValue(company);
    this.states = {};
    this.submitted = false;
    this.contactUsForm.markAsPristine();
    this.contactUsForm.markAsUntouched();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
