import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-invoice-list-detail-items',
  templateUrl: './kn-br-invoice-list-detail-items.component.html',
  styleUrls: ['./kn-br-invoice-list-detail-items.component.scss'],
})
export class KnBrInvoiceListDetailItemsComponent implements OnInit, OnDestroy {
  address;
  invoiceData;
  invoiceItems = [];
  subTaxCharges = [];
  subFrieghtCharges = [];
  showRemittanceAddress = false;
  subscription = new Subscription();
  createCaseHeadertoolTipText = `If you need assistance with this order,please click 'Submit Case'`;
  createCaseLineItemtoolTipText = `If you need assistance with this product,please click 'Submit Case'`;

  constructor(
    public knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    sessionStorage.getItem('showRemittanceAddress') === 'true'
      ? (this.showRemittanceAddress = true)
      : (this.showRemittanceAddress = false);
    this.subscription.add(
      this.knBrCommonService.invoiceData$.subscribe((res) => {
        if (res) {
          this.invoiceData = res;
          this.formatAddress(this.invoiceData);
          this.invoiceItems = this.invoiceData.materials;
          this.subTaxCharges = this.invoiceData.subTaxCharges;
          this.subFrieghtCharges = this.invoiceData.subFreightCharges;
          this.cdr.detectChanges()
        }
      })
    );
  }

  formatAddress(data) {
    if (data?.remittanceAddress) {
      return this.address =
        data.remittanceAddress.firstName ? data.remittanceAddress.firstName : '' +
        '' + data.remittanceAddress.street +
        ', ' + data.remittanceAddress.city +
        ', ' + data.remittanceAddress.postalCode;
    }
  }

  toFixed(value) {
    if (value) {
      const numberToFormat = Number(value);
      return numberToFormat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  separator(value) {
    if (value != 'null') {
      return this.knBrCommonService.separator(value);
    }
  }

  formatNetPrice(value) {
    if (value?.startsWith('+') || value?.startsWith('-')) {
      return value.slice(1);
    } else {
      return value;
    }
  }

  navigateToCaseCreationHeader(documentNo) {
    const urlValue = `my-account/case-creation/invoice?documentNumber=${documentNo}`;
    this.routingService.goByUrl(urlValue);
  }

  navigateToCaseCreationMaterialItem(documentNo, matPosNo) {
    const urlValue = `my-account/case-creation/invoice?documentNumber=${documentNo}&matPosNo=${matPosNo}`;
    this.routingService.goByUrl(urlValue);
  }

  goBack() {
    this.routingService.go({ cxRoute: 'invoiceList' }, { state: { displayPersistedValues: true }});
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.knBrCommonService.invoiceData$.next(null);
    }
  }
}
