import { isEmpty, includes } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { KnBrCommonService } from '../services/kn-br-common.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import {
  complaintCodes,
  complaintPolicies,
  incorrectCodes,
  invoiceRelatedCodes,
  productDefectCodes,
  quoteFields,
  transportCodes,
  wrongQtyCodes,
} from '../shared/helpers/case-helpers';
import {
  KNBR_CASE_CREATE,
  KNBR_CASE_CREATE_UPLOAD_FILE,
  KNBR_GET_ALTERNATE_UOMS,
  KNBR_GET_CASE_TYPES,
  KNBR_GET_INFO_TO_CREATE_CASE,
  KNBR_GET_INVOICE_INFO_TO_CREATE_CASE,
  KNBR_GET_ISSUE_SUB_TYPES,
  KNBR_GET_ISSUE_TYPES,
  KNBR_GET_PRODUCTS,
  KNBR_GET_PRODUCTS_FROM_INVOICE,
  KNBR_GET_SHIPMENTS_FOR_CASE,
  KNBR_GET_SHIPMENTS_FROM_INVOICE,
  KNBR_GET_SKU_SUGGESTIONS_TO_CREATE_CASE,
  KN_BR_CUSTOMER_CURRENT,
} from 'src/constants/api.endpoints.constant';
import { CAST_TYPES_BACKUP } from 'src/constants/pagination.constant';
import { KnBrDateHelper } from '../shared/kn-br-date.helper';
import * as moment from 'moment';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Location } from '@angular/common';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'kn-br-case-create',
  templateUrl: './kn-br-case-create.component.html',
  styleUrls: ['./kn-br-case-create.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrCaseCreateComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  displayinitialMainDropdown = true;
  orderUrlObject;
  invoiceUrlObject;
  orderNumber;
  jobName;
  salesArea;
  salesGroup;
  salesOffice;
  salesUnit;
  defaultQuantityValue = '';
  currentDateValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  uploadedFileObjects = [];
  todayDate = () => {
    const today = new Date();
    this.currentDateValue = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    return { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
  };
  maxStartDate = () => this.todayDate();
  customerId: string;
  caseCreationForm: UntypedFormGroup;
  helperForm: UntypedFormGroup;
  caseTypesData = [];
  issueTypesData = [];
  issueSubTypesData = [];
  productsData = [];
  showAdditionalDetails = false;

  selectedCaseType: any;
  selectedIssueType: any;
  selectedIssueSubType: any;

  showIssueType = false;
  showIssueSubType = false;
  showIncidentDate = false;
  showCaseDesc = false;
  showProducts = false;
  showMultiSelectProducts = false;
  showBatchNumber = false;
  showQuantity = false;
  showRequestedSku = false;
  showDeliveredSku = false;
  // showInvoiceNumber = false;
  showShipmentNumber = false;
  shipmentResponseData: { name: any; value: string }[];
  showUploadFileSection = false;
  submitted: boolean = false;
  multipleSelectedProducts = [];
  showSpinner: any;
  caseFromInvoice = false;
  allApiSubscriptions = new Subscription();
  alternateUomData = {}
  options$: Observable<string[]>;
  selectedDeliveredSkuName;
  selectedRequestedSkuEntryNumber;
  isDragOver: boolean = false;
  invoicesForTheOrder: any = [];

  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    private endPointService: OccEndpointsService,
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    private knBrDateHelper: KnBrDateHelper,
    protected globalMessageService: GlobalMessageService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected router: Router,
    protected _location: Location
  ) {
    setTimeout(()  => {
      this.options$ = this.caseCreationForm.get('deliveredSku')!.valueChanges.pipe(
        debounceTime(300),
        switchMap(value => this.fetchOptions(value))
      );
    }, 2000)
  }

  ngOnInit(): void {
    if (this.router.url.includes('/invoice')) {
      this.caseFromInvoice = true;
    }
    this.initForm();
    this.allApiSubscriptions.add(
       this.activatedRoute.queryParams.subscribe((params) => {
      if (this.caseFromInvoice) {
        this.invoiceUrlObject = {
          documentNumber: params['documentNumber'] || null,
          matPosNo: params['matPosNo'] || '',
        };
        this.invoiceUrlObject = this.removeEmptyEntriesfromObject(this.invoiceUrlObject);
      } else {
        this.orderUrlObject = {
          orderNumber: params['orderNumber'] || null,
          materialNumber: params['materialNumber'] || '',
          entryNumber: params['entryNumber'] || ''
        };
        this.orderUrlObject = this.removeEmptyEntriesfromObject(this.orderUrlObject);
      }
      this.getJobInfoAndCaseTypes();
    }));
  }

  getJobInfoAndCaseTypes() {
    this.allApiSubscriptions.add(this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
      this.getJobNameResponse();
      this.getCaseTypesData();
    }));
  }

  initForm() {
    this.helperForm = this.fb.group({
      orderBaseDocumentNumber: [null],
      proxyCaseType: [null, Validators.required],
    });

    this.caseCreationForm = this.fb.group({
      uom: [null],
      caseType: [null],
      salesArea: [null],
      salesGroup: [null],
      salesOffice: [null],
      salesUnit: [null],
      orderNumber: [null],
      documentNumber: [null],
      issueType: [{ value: null, disabled: true }, [Validators.required]],
      issueSubType: [{ value: null, disabled: true }, [Validators.required]],
      caseDescription: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(512)]],
      incidentDate: [{ value: null, disabled: true }, []],
      products: [{ value: null, disabled: true }, []],
      multiSelectProducts: [{ value: null, disabled: true }, []],
      batchNumber: [{ value: null, disabled: true }, []],
      quantity: [{ value: null, disabled: true }, []],
      requestedSku: [{ value: null, disabled: true }, [Validators.required]],
      deliveredSku: [{ value: null, disabled: true }, [Validators.required]],
      invoiceNumber: [{ value: null, disabled: true }, []],
      shipmentNumber: [{ value: null, disabled: true }, []],
      fileInput: [''],
    });
  }

  getJobNameResponse() {
    let url;
    if (this.caseFromInvoice) {
      url = this.endPointService.buildUrl(KNBR_GET_INVOICE_INFO_TO_CREATE_CASE(this.customerId), {
        queryParams: this.invoiceUrlObject,
      });
    } else {
      url = this.endPointService.buildUrl(KNBR_GET_INFO_TO_CREATE_CASE(this.customerId), {
        queryParams: this.orderUrlObject,
      });
      this.helperForm.get('orderBaseDocumentNumber').setValidators([Validators.required]);
    }
    this.allApiSubscriptions.add(this.http.get(url).subscribe((response: any) => {
      this.orderNumber = response.orderNumber;
      this.jobName = response.jobName;
      this.salesArea = response.salesArea;
      this.salesGroup = response.salesGroup;
      this.salesOffice = response.salesOffice;
      this.salesUnit = response.salesUnit;
      this.invoicesForTheOrder = response.invoices;
      if(this.caseFromInvoice === false && response.invoices.length === 1) {
        this.helperForm.controls.orderBaseDocumentNumber.patchValue(response.invoices[0]);
      }
      this.cdr.detectChanges();
    }));
  }

  // CaseTypes
  getCaseTypesData() {
    const url = this.endPointService.buildUrl(KNBR_GET_CASE_TYPES(this.customerId), {queryParams: {filterCaseTypes: true}});
    this.allApiSubscriptions.add(this.http.get(url).subscribe(
      (response) => {
        const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        const sortedFormat = this.knBrCommonService.alphabeticalSort(formattedResponse, 'name');
        this.caseTypesData = sortedFormat;
      },
      (error) => {
        const formattedResponse = Object.keys(CAST_TYPES_BACKUP).map((key) => ({
          name: CAST_TYPES_BACKUP[key],
          value: key,
        }));
        this.caseTypesData = formattedResponse;
      }
    ));
  }

  onInvoiceSelectionChange(selectedInvoice) {
    console.log("logging selected invoice", selectedInvoice);
    this.helperForm.get('proxyCaseType').reset();
    this.caseCreationForm.reset();
    this.disableAllFields();
  }

  // caseTypeChange
  onCaseTypeChange(selectedOption) {
    this.showIssueSubType = false;
    this.caseCreationForm.reset();
    this.disableAllFields();
    this.submitted = false;
    this.uploadedFileObjects = [];
    this.uploadedFileObjects = [];
    this.multipleSelectedProducts = [];
    this.caseCreationForm.controls.incidentDate.patchValue(this.currentDateValue);
    this.caseCreationForm.controls.quantity.patchValue(this.defaultQuantityValue);
    if(selectedOption.value === 'ZSC_PD2' || selectedOption.value === 'ZSC_WQD2') {
      this.caseCreationForm.get('products').setValidators([Validators.required]);
    } else if (selectedOption.value === 'ZSC_PD2_1') {
      this.caseCreationForm.get('multiSelectProducts').setValidators([Validators.required]);
    } else {
      this.caseCreationForm.get('products').clearValidators()
      this.caseCreationForm.get('multiSelectProducts').clearValidators();
    }
    this.caseCreationForm.get('products')?.updateValueAndValidity();
    this.caseCreationForm.get('multiSelectProducts')?.updateValueAndValidity();
    this.getIssueTypesData(selectedOption.value);
    this.cdr.detectChanges();
  }

  // issueTypes
  getIssueTypesData(selectedCaseType) {
    const quantity = this.caseCreationForm.get('quantity');
    const requestedSku = this.caseCreationForm.get('requestedSku');
    const deliveredSku = this.caseCreationForm.get('deliveredSku');
    const products = this.caseCreationForm.get('products');
    const multiselectProducts = this.caseCreationForm.get('multiSelectProducts');
    // const defectDescription = this.caseCreationForm.get('defectDescription');
    const issueType = this.caseCreationForm.get('issueType');

    if (selectedCaseType) {
      this.selectedCaseType = selectedCaseType;
      const url = this.endPointService.buildUrl(KNBR_GET_ISSUE_TYPES(this.customerId));
      const urlWithParams = url + '?caseTypeCode=' + selectedCaseType;
      this.allApiSubscriptions.add(this.http.get(urlWithParams).subscribe((response) => {
        const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        const sortedIssues = this.knBrCommonService.alphabeticalSort(formattedResponse, 'name');
        this.issueTypesData = sortedIssues;
        if (!isEmpty(response)) {
          this.showIssueType = true;
          issueType.enable();
        } else {
          this.showIssueType = false;
          issueType.disable();
          this.enableCommonFields();
          if (selectedCaseType === 'ZSC_WPD2') {
            this.showQuantity = true;
            this.showRequestedSku = true;
            this.showDeliveredSku = true;
            this.showProducts = false;
            this.showMultiSelectProducts = false;
            this.getProductData();
            this.knBrCommonService.enableControls([quantity, requestedSku, deliveredSku]);
          } else if (selectedCaseType === 'ZSC_PD2_1') {
            this.showMultiSelectProducts = true;
            this.showQuantity = false;
            this.showRequestedSku = false;
            this.showDeliveredSku = false;
            this.knBrCommonService.enableControls([multiselectProducts, products]);
            this.getProductData();
          }
        }
        this.cdr.detectChanges();
      }));
    } else if (selectedCaseType === 'ZSC_WQD2') {
      this.showQuantity = true;
    }
  }

  getSingleSelectedProductValue() {
    if(this.showRequestedSku) {
    const selectedsku = this.caseCreationForm.controls['requestedSku'].getRawValue()
    return selectedsku ? selectedsku : ''
    }
    const selectedProductCode = this.caseCreationForm.controls['products'].getRawValue()
    return selectedProductCode ? selectedProductCode : ''
  }

  onProductChange(selectedOption) {
    this.caseCreationForm.controls['uom'].reset();
    this.getAlternateUomData();
  }

  onMultiSelectProductChange(selectedOnes) {
    this.multipleSelectedProducts = this.updateSelectedProductArrays(this.multipleSelectedProducts, selectedOnes);
    this.getAlternateUomData();
  }

  handleRequestedSkuSelection(data) {
    this.selectedRequestedSkuEntryNumber = data.entryNumber;
    this.getAlternateUomData()
  }

  getAlternateUomData() {
     if(this.multipleSelectedProducts.length) {
      this.multipleSelectedProducts.forEach(element => {
        if(this.alternateUomData.hasOwnProperty(element.code) === false) {
          const alternateUomUrl = this.endPointService.buildUrl(KNBR_GET_ALTERNATE_UOMS(this.customerId, element.code, this.salesArea))
          this.allApiSubscriptions.add(this.http.get(alternateUomUrl).subscribe(response => {
            const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
            this.alternateUomData[element.code] = formattedResponse;
            this.cdr.detectChanges();
          }))
      }});
     } else if(this.caseCreationForm.controls['products'].getRawValue()) {
      const productCode = this.caseCreationForm.controls['products'].getRawValue()
      const alternateUomUrl = this.endPointService.buildUrl(KNBR_GET_ALTERNATE_UOMS(this.customerId, productCode, this.salesArea))
      this.allApiSubscriptions.add(this.http.get(alternateUomUrl).subscribe(response => {
        const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        this.alternateUomData[productCode] = formattedResponse;
        console.log("alternate uom data", this.alternateUomData);
        this.cdr.detectChanges();
      }));
     } else if (this.caseCreationForm.controls['requestedSku'].getRawValue() && this.showRequestedSku) {
      const selectedsku = this.caseCreationForm.controls['requestedSku'].getRawValue()
      const alternateUomUrl = this.endPointService.buildUrl(KNBR_GET_ALTERNATE_UOMS(this.customerId, selectedsku, this.salesArea))
      this.allApiSubscriptions.add(this.http.get(alternateUomUrl).subscribe(response => {
        const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        this.alternateUomData[selectedsku] = formattedResponse;
        console.log("alternate uom data", this.alternateUomData);
        this.cdr.detectChanges();
      }));
     }

  }

  // issueTypeChange
  onIssueTypeChange(selectedOption) {
    this.caseCreationForm.controls.issueSubType.reset();
    this.selectedIssueType = selectedOption.value;
    this.getIssueSubTypesData(selectedOption.value);
  }

  quantityChangeAfterMultiSelect(event, productObj) {
    const isNumberOnly = this.numberOnly(event);
    if (isNumberOnly) {
      productObj.quantity = event.target.value;
      this.multipleSelectedProducts = this.multipleSelectedProducts.map((item) =>
        item.code === productObj.code ? productObj : item
      );
    }
  }

  uomChangeAfterMultiSelect(selectedUom, productObj) {
    productObj.uom = selectedUom.value
    this.multipleSelectedProducts = this.multipleSelectedProducts.map((item) =>
      item.code === productObj.code ? productObj : item
    );
  }

  numberOnly(event): boolean {
    const value = event.target.value;
    if (typeof value === 'number') {
      return false;
    }
    return true;
  }

  // subIssueTypes
  getIssueSubTypesData(selectedIssueType) {
    const issueSubType = this.caseCreationForm.controls.issueSubType;
    if (!includes(wrongQtyCodes, selectedIssueType)) {
      const url = this.endPointService.buildUrl(KNBR_GET_ISSUE_SUB_TYPES(this.customerId));
      const urlWithParams = url + '?issueTypeCode=' + selectedIssueType;
      this.allApiSubscriptions.add(this.http.get(urlWithParams).subscribe((response) => {
        const formattedResponse = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        this.issueSubTypesData = formattedResponse;
        if (!isEmpty(response)) {
          this.showIssueSubType = true;
          issueSubType.enable();
        } else if (selectedIssueType === 'ZSC_NS3') {
          this.getShipmentData();
          this.showAdditionalDetails = true;
          this.showShipmentNumber = true;
          this.showIssueSubType = false;
          this.enableCommonFields();
          issueSubType.disable();
          this.caseCreationForm.controls.shipmentNumber.enable();
        } else if(this.selectedCaseType && this.selectedCaseType === 'ZSC_CMP') {
          this.enableCommonFields();
        } else {
          this.showIssueSubType = false;
          issueSubType.disable();
        }
        this.cdr.detectChanges();
      }));
    } else {
      this.wrongQtyFields();
      this.cdr.detectChanges()
    }
  }

  wrongQtyFields() {
    this.enableCommonFields();
    this.showProducts = true;
    this.showQuantity = true;
    this.caseCreationForm.get('products').enable();
    this.caseCreationForm.get('quantity').enable();
    this.getProductData();
  }

  // subIssueTypeChange
  onSubIssueTypeChange(selectedOption) {
    if(this.selectedCaseType && this.selectedCaseType !== 'ZSC_CT') {
      this.showAdditionalDetails = true;
    }
    const quantity = this.caseCreationForm.get('quantity');
    const products = this.caseCreationForm.get('products');
    const batchNumber = this.caseCreationForm.get('batchNumber');
    // const invoiceNumber = this.caseCreationForm.get('invoiceNumber');
    const shipmentNumber = this.caseCreationForm.get('shipmentNumber');
    const fileInput = this.caseCreationForm.get('fileInput');
    this.selectedIssueSubType = selectedOption;
    this.enableCommonFields();
    this.getProductData();
    // clear before selection before enabling anything
    this.showProducts = false;
    this.showBatchNumber = false;
    this.showQuantity = false;
    // this.showInvoiceNumber = false;
    this.knBrCommonService.disableControls([products, batchNumber, quantity])
    if (includes(productDefectCodes, selectedOption.value)) {
      this.showCaseDesc = true;
      this.showIncidentDate = true;
      this.showUploadFileSection = true;
      this.showProducts = true;
      this.showBatchNumber = true;
      this.showQuantity = true;
      this.knBrCommonService.enableControls([quantity, batchNumber, products, fileInput]);
    } else if (includes(complaintCodes, selectedOption.value) || includes(invoiceRelatedCodes, selectedOption.value)) {
      this.showQuantity = true;
      // this.showInvoiceNumber = true;
      this.showProducts = true;
      this.enableComplaintFields();
    } else if (includes(complaintPolicies, selectedOption.value)) {
      this.enableCommonFields();
    } else if (includes(quoteFields, selectedOption.value)) {
      // this.showInvoiceNumber = true;
      this.showProducts = true;
      this.knBrCommonService.enableControls([products]);
    } else if (includes(incorrectCodes, selectedOption.value)) {
      // this.showInvoiceNumber = true;
      // invoiceNumber.enable();
    } else if (includes(transportCodes, selectedOption.value)) {
      this.getShipmentData();
      this.showShipmentNumber = true;
      shipmentNumber.enable();
      this.enableCommonFields();
    }
  }

  enableComplaintFields() {
    const quantity = this.caseCreationForm.get('quantity');
    const products = this.caseCreationForm.get('products');
    // const invoiceNumber = this.caseCreationForm.get('invoiceNumber');
    this.knBrCommonService.enableControls([quantity, products]);
  }

  enableCommonFields() {
    this.showCaseDesc = true;
    this.showIncidentDate = true;
    this.showUploadFileSection = true;
    this.knBrCommonService.enableControls([
      this.caseCreationForm.get('incidentDate'),
      this.caseCreationForm.get('caseDescription'),
      this.caseCreationForm.get('fileInput'),
    ]);
  }

  disableAllFields() {
    this.showCaseDesc = false;
    this.showIncidentDate = false;
    this.showUploadFileSection = false;
    this.showProducts = false;
    this.showMultiSelectProducts = false;
    this.showBatchNumber = false;
    this.showQuantity = false;
    this.showRequestedSku = false;
    this.showDeliveredSku = false;
    // this.showInvoiceNumber = false;
    this.showShipmentNumber = false;
    this.showAdditionalDetails = false;
    const caseDescription = this.caseCreationForm.get('caseDescription');
    const incidentDate = this.caseCreationForm.get('incidentDate');
    const products = this.caseCreationForm.get('products');
    const multiSelectProducts = this.caseCreationForm.get('multiSelectProducts');
    // const defectDescription = this.caseCreationForm.get('defectDescription');
    const batchNumber = this.caseCreationForm.get('batchNumber');
    const quantity = this.caseCreationForm.get('quantity');
    const requestedSku = this.caseCreationForm.get('requestedSku');
    const deliveredSku = this.caseCreationForm.get('deliveredSku');
    // const invoiceNumber = this.caseCreationForm.get('invoiceNumber');
    const shipmentNumber = this.caseCreationForm.get('shipmentNumber');
    const fileInput = this.caseCreationForm.get('fileInput');
    const issueType = this.caseCreationForm.get('issueType');
    const issueSubType = this.caseCreationForm.get('issueSubType');
    this.knBrCommonService.disableControls([
      caseDescription,
      incidentDate,
      products,
      multiSelectProducts,
      batchNumber,
      quantity,
      requestedSku,
      deliveredSku,
      shipmentNumber,
      fileInput,
      issueType,
      issueSubType
    ]);
  }

  getProductData() {
    let urlWithParams;
    if(this.caseFromInvoice) {
      urlWithParams = this.endPointService.buildUrl(KNBR_GET_PRODUCTS_FROM_INVOICE(this.customerId), {
        queryParams: this.invoiceUrlObject,
      });
    } else {
      const selectedInvoice = this.helperForm.get('orderBaseDocumentNumber').value;
      urlWithParams = this.endPointService.buildUrl(KNBR_GET_PRODUCTS(this.customerId), {
        queryParams: {
          documentNumber: selectedInvoice,
          materialNumber: this.orderUrlObject.materialNumber,
          entryNumber: this.orderUrlObject.entryNumber
        }
      });
    }
    this.allApiSubscriptions.add(this.http.get(urlWithParams).subscribe((response: any) => {
      if (response) {
        let products = []
          products = response.products;
        const updatedProductsData = products.map((eachProduct) => {
          return {
            code: eachProduct.code,
            description:  eachProduct.description,
            labelDescription: `${eachProduct.code} - ${eachProduct.description}`,
            quantity: "",
            entryNumber: eachProduct.entryNumber,
            uom: eachProduct.uom,
          };
        });

        this.productsData = updatedProductsData;
        console.log("logging products data", this.productsData);
        if (
          (this.orderUrlObject &&
          this.orderUrlObject.materialNumber) || (this.invoiceUrlObject && this.invoiceUrlObject.matPosNo) &&
          this.productsData &&
          this.productsData.length === 1
        ) {
          if (this.selectedCaseType === 'ZSC_PD2_1') {
            const preSelectData = [...this.productsData];
            this.caseCreationForm.controls.multiSelectProducts.patchValue(preSelectData);
            this.multipleSelectedProducts = preSelectData;
            this.getAlternateUomData();
          } else if (this.showProducts === true) {
            this.caseCreationForm.controls.products.patchValue(this.productsData[0].code);
            this.getAlternateUomData();
          }
        }
        console.log("is form valid", this.caseCreationForm.valid);
        this.cdr.detectChanges();
      }
    }));
  }

  // get shipment dropdown data
  getShipmentData() {
    let shipmentUrl;
    if(this.caseFromInvoice) {
      shipmentUrl = this.endPointService.buildUrl(KNBR_GET_SHIPMENTS_FROM_INVOICE(this.customerId), {
        queryParams: this.invoiceUrlObject,
      });
    } else {
      const selectedInvoice = this.helperForm.get('orderBaseDocumentNumber').value;
      shipmentUrl = this.endPointService.buildUrl(KNBR_GET_SHIPMENTS_FROM_INVOICE(this.customerId), {
       queryParams: {
        documentNumber: selectedInvoice
       }
     });
    }
    this.allApiSubscriptions.add(this.http.get(shipmentUrl).subscribe((resp) => {
      const formattedResponse = Object.keys(resp).map((key) => ({ name: resp[key], value: key }));
      this.shipmentResponseData = formattedResponse;
    }));
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    if (event.dataTransfer?.files) {
      if (event.dataTransfer.files.length > 1) {
        // alert('Please drag and drop only one file at a time.');
        // return;
      }
      // this.onFileSelected(null, event.dataTransfer.files);
    }
  }

  onFileSelected(event, dragFiles?) {
    this.onSpinnerLoading(true);
    const fileUploadUrl = this.endPointService.buildUrl(KNBR_CASE_CREATE_UPLOAD_FILE(this.customerId));
    const file = dragFiles ? dragFiles[0] : event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    this.allApiSubscriptions.add(this.http.post(fileUploadUrl, formData).subscribe(
      (response: any) => {
        this.onSpinnerLoading(false);
        if (response && response.fileReferenceId) {
          const fileSizeInBytes = file.size;
          const fileSizeInKB = fileSizeInBytes / 1024;
          const fileObj = { ...response, fileSize: fileSizeInKB.toFixed(2) };
          this.uploadedFileObjects.push(fileObj);
          this.caseCreationForm.controls['fileInput'].reset();
          this.cdr.detectChanges();
        } else {
          this.caseCreationForm.controls['fileInput'].reset();
          this.globalMessageService.add(
            'Something went wrong, please check the file type',
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      },
      (error) => {
        this.globalMessageService.add('Something went wrong', GlobalMessageType.MSG_TYPE_ERROR);
        this.onSpinnerLoading(false);
        this.caseCreationForm.controls['fileInput'].reset();
      }
    ));
  }

  addCustomOption(name: string): string {
    return name;
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  goBack() {
    this.allApiSubscriptions.unsubscribe();
    if(this.caseFromInvoice) {
      this._location.back();
    } else {
      const url = 'customer-360/order/' + this.orderNumber;
      this.routingService.go(url);
    }
  }

  submitForm() {
    this.submitted = true;
    if (this.caseCreationForm.valid) {
      this.caseCreationForm.controls.salesArea.patchValue(this.salesArea);
      this.caseCreationForm.controls.salesGroup.patchValue(this.salesGroup);
      this.caseCreationForm.controls.salesOffice.patchValue(this.salesOffice);
      this.caseCreationForm.controls.salesUnit.patchValue(this.salesUnit);
      if(this.caseFromInvoice) {
        this.caseCreationForm.controls.documentNumber.patchValue(this.invoiceUrlObject.documentNumber);
        this.caseCreationForm.controls.orderNumber.patchValue(this.orderNumber);
      } else {
        this.caseCreationForm.controls.orderNumber.patchValue(this.orderUrlObject.orderNumber);
      }
      this.caseCreationForm.controls.caseType.patchValue(this.helperForm.controls.proxyCaseType.value);
      const modifiedPayload = this.getPayloadFromForm();
      this.sendRequestToCreateCase(modifiedPayload);
    } else {
      this.caseCreationForm.markAllAsTouched();
    }
  }

  getPayloadFromForm() {
    const payload = this.caseCreationForm.getRawValue();
    const updatedPayload = { ...payload };
    const strDate = this.knBrDateHelper.formatDate(updatedPayload.incidentDate);
    const parsedDate = moment(strDate, 'YYYYMMDD');
    const formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
    updatedPayload.incidentDate = formattedDate;
    updatedPayload.fileReferenceId = this.uploadedFileObjects.map((obj) => obj.fileReferenceId);
    updatedPayload.fileNames = this.uploadedFileObjects.map((obj) => obj.fileName);
    updatedPayload.jobName = this.jobName;
    updatedPayload.soldTo = this.customerId;
    if(updatedPayload.deliveredSku) {
      updatedPayload.deliveredSkuName = this.selectedDeliveredSkuName;
    } else {
      updatedPayload.deliveredSkuName = ''
    }
    if (updatedPayload.caseType === 'ZSC_PD2_1') {
      if(this.multipleSelectedProducts.length) {
        this.multipleSelectedProducts.forEach(obj => {
          delete obj.labelDescription;
        })
      }
      updatedPayload.products = this.multipleSelectedProducts;
    } else if (updatedPayload.products) {
      const finalizedProducts = [];
      updatedPayload.products = this.productsData.find((eachProd) => eachProd.code === updatedPayload.products);
      updatedPayload.products.quantity = updatedPayload.quantity;
      updatedPayload.products.uom = updatedPayload.uom;
      delete updatedPayload.products.labelDescription;
      finalizedProducts.push(updatedPayload.products);
      updatedPayload.products = finalizedProducts;
    }
    if(updatedPayload.products && updatedPayload.products.length) {
      delete updatedPayload.quantity;
      delete updatedPayload.uom;
    } else if (updatedPayload.requestedSku && this.selectedCaseType === 'ZSC_WPD2') {
      updatedPayload.entryNumber = this.selectedRequestedSkuEntryNumber || ''
    }
    // else {
    //   if(this.caseFromInvoice) {
    //     updatedPayload.entryNumber = this.invoiceUrlObject.matPosNo;
    //   }
    // }

    if(this.caseFromInvoice === false) {
      updatedPayload.invoiceNumber = this.helperForm.get('orderBaseDocumentNumber')?.value;
    }
    delete updatedPayload.fileInput;
    return updatedPayload;
  }

  sendRequestToCreateCase(payloadData) {
    const url = this.endPointService.buildUrl(KNBR_CASE_CREATE(this.customerId));
    this.allApiSubscriptions.add(this.http.post(url, payloadData).subscribe((response) => {
      this.globalMessageService.add('Your case has been created.', GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.goBack();
    }));
  }

  removeEmptyEntriesfromObject(dataObj) {
    const dataObjNew = { ...dataObj };
    Object.keys(dataObjNew).forEach((k) => dataObjNew[k] == null && delete dataObjNew[k]);
    return dataObjNew;
  }

  removeTheSelectedItem(codeValue) {
    this.multipleSelectedProducts = this.multipleSelectedProducts.filter((obj) => obj.code != codeValue);
    this.caseCreationForm.controls.multiSelectProducts.patchValue(this.multipleSelectedProducts);
    this.cdr.detectChanges();
  }

  removeSelectedFile(fileId) {
    this.uploadedFileObjects = this.uploadedFileObjects.filter((eachObj) => eachObj.fileReferenceId != fileId);
    this.cdr.detectChanges();
  }

  handleSelectedDeliveredsku(data) {
    this.selectedDeliveredSkuName = data.deliveredSkuName;
  }

  updateSelectedProductArrays(arrayOne, arrayTwo) {
    // Remove objects from arrayOne that are not in arrayTwo
    const filteredArrayOne = arrayOne.filter((obj1) => arrayTwo.some((obj2) => obj1.code === obj2.code));

    // Add unique objects from arrayTwo to arrayOne
    for (const obj2 of arrayTwo) {
      const existingObj = filteredArrayOne.find((obj1) => obj1.code === obj2.code);
      if (!existingObj) {
        filteredArrayOne.push(obj2);
      }
    }

    return filteredArrayOne;
  }

  getQuantityLabelName() {
    if (this.selectedCaseType === 'ZSC_PD2') {
      return 'Quantity Defective';
    } else if(this.selectedCaseType === 'ZSC_WQD2') {
      if(this.selectedIssueType === 'ZSC_LIT3') {
        return 'Quantity Not Received'
      } else if(this.selectedIssueType === 'ZSC_UO3' || this.selectedIssueType === 'ZSC_US3') {
        return 'Quantity Under'
      } else if(this.selectedIssueType === 'ZSC_OO3' || this.selectedIssueType === 'ZSC_OS3') {
        return 'Quantity Over'
      } else if(this.selectedIssueType === 'ZSC_NPPS3') {
        return 'Quantity'
      }
    }
    return 'Quantity';
  }


  fetchOptions(query: string): Observable<any> {
    if (!query) {
      return of([]); // Return empty array if query is empty
    }
    // Replace with your actual API endpoint
    const skuSuggestionurl = this.endPointService.buildUrl(KNBR_GET_SKU_SUGGESTIONS_TO_CREATE_CASE(this.customerId)) + `?searchTerm=${query}`
    return this.http.get<any>(skuSuggestionurl).pipe(map(response => response.products || []));
  }


  ngOnDestroy(): void {
    this.allApiSubscriptions.unsubscribe();
    this.caseCreationForm.reset();
    this.disableAllFields();
    this.uploadedFileObjects = [];
    this.multipleSelectedProducts = [];
  }
}
