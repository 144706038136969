import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CxDatePipe, RoutingService, TranslationService, UserService, WindowRef } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import * as _ from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrConfirmationDialogService } from 'src/app/common/kn-br-confirm-dialog/kn-br-confirm-dialog.service';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';

import { KnBrQuote } from '../../../models/kn-br-quote.model';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCommonService } from './../../../services/kn-br-common.service';
@Component({
  selector: 'kn-br-quote-search-result',
  templateUrl: './kn-br-quote-search-result.component.html',
  styleUrls: ['./kn-br-quote-search-result.component.scss'],
  providers: [CxDatePipe],
})
export class KnBrQuoteSearchResultComponent implements OnInit, OnDestroy {
  quoteSearchResult$: Observable<KnBrQuote[]> = this.knBrQuoteService.list$;
  isLoading$: Observable<boolean> = this.knBrQuoteService.loading$;
  paginate$: Observable<any> = this.knBrQuoteService.paginate$;
  sorts$: Observable<any> = this.knBrQuoteService.sorts$;
  subscription: any;
  copyActionSubscription: any;
  sortType: string;
  searchCriteria: any;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  copyAction: any;
  enableCheckboxFlag = false;
  allChecked = false;
  selectedProductsCodes: string[] = [];
  productCodes: string[] = [];
  downloadProgress = false;

  constructor(
    protected currentUserService: UserAccountService,
    protected knBrQuoteService: KnBrQuotesService,
    protected routeService: RoutingService,
    protected confirmationDialogService: KnBrConfirmationDialogService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected winRef: WindowRef,
    protected knBrCommonService: KnBrCommonService,
    protected translation: TranslationService,
    protected cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.subscription = this.knBrQuoteService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          this.sortType = paginate.sort;
        }
      })
    );
    this.copyActionSubscription = this.currentUserService
      .get()
      .subscribe((user: any) => (this.copyAction = user ? user.copyAction : false));
    this.knBrCommonService.setQuoteListSelectedProducts([]);
    this.knBrCommonService.setDownloadFlag(false);
    this.subscription.add(
      this.knBrQuoteService.list$.subscribe((quotes) => {
        if (quotes) {
          this.productCodes = quotes.map((quote) => quote.code);
          if (this.allChecked) {
            this.selectedProductsCodes = _.cloneDeep(this.productCodes);
            this.knBrCommonService.setQuoteListSelectedProducts(this.selectedProductsCodes);
          }
        }
      })
    );
    this.subscription.add(
      this.knBrCommonService.loadDownloadFlag$().subscribe((flag) => {
        this.enableCheckboxFlag = flag;
        if (!flag) {
          this.knBrCommonService.setQuoteListSelectedProducts([]);
          this.allChecked = false;
        }
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCommonService.loadQuoteListSelectedProducts$().subscribe((selectedProducts) => {
        this.selectedProductsCodes = selectedProducts;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(this.isLoading$.subscribe({
      next: response => {
        this.knBrCommonService.setSpinner(response)
      },
      error: error => { }
    }))
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.copyActionSubscription) {
      this.copyActionSubscription.unsubscribe();
    }
  }
  copyQuoteConent(quote: KnBrQuote) {
    this.showDialog(quote);
  }
  showDialog(quote) {
    const params = {
      code: quote.code,
      name: quote.jobName,
      isQuote: true,
    };
    // this.confirmationDialogService
    //   .confirm('customer.context.changeCustomerLabel', 'customer.context.changeCustomerMessage')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.winRef.localStorage.setItem('copyObject', JSON.stringify(params));
    //       this.routeService.go({ cxRoute: 'home' }, null);
    //     } else {
    //       this.knBrCustomerContextService.set({ customerName: quote.accountName, customerNumber: quote.accountNumber });
    //       this.redirectToCopy(params);
    //     }
    //   })
    //   .catch(() => {});
    this.redirectToCopy(params);
  }
  redirectToDetail(event, quote) {
    if (
      event.target &&
      event.target.classList &&
      !event.target.classList.contains('custom-control-input') &&
      !event.target.classList.contains('copy-action') &&
      !event.target.classList.contains('fa-copy')
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.knBrCustomerContextService.set({
        customerNumber: quote.accountNumber,
        customerName: quote.accountName,
        telephone: quote.accountPhone,
      });
      this.knBrQuoteService.get(quote.code);
      this.routeService.go({ cxRoute: 'quoteDetails', params: { code: quote.code } });
    }
  }
  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrQuoteService.search(this.searchCriteria);
  }
  enableCheckbox() {
    this.enableCheckboxFlag = true;
  }
  cancelDownload() {
    this.enableCheckboxFlag = false;
    this.selectedProductsCodes = [];
    this.allChecked = false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    event.currentTarget.checked ? this.addProductCode(event.target.value) : this.removeProductCode(event.target.value);
  }
  isChecked(code: string): boolean {
    if (this.selectedProductsCodes) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }
  downloadPDF() {
    this.knBrCommonService.downloadQuote(this.selectedProductsCodes.toString(), this.selectedProductsCodes.length > 1);
    this.cancelDownload();
  }
  selectAll(event) {
    this.allChecked = event.currentTarget.checked ? true : false;
    this.selectedProductsCodes = event.currentTarget.checked ? _.cloneDeep(this.productCodes) : [];
    this.knBrCommonService.setQuoteListSelectedProducts(this.selectedProductsCodes);
  }
  redirectToCopy(params) {
    const payload = {
      referenceQuoteId: params.code,
    };
    this.knBrQuoteService.copy(payload);
  }
  addProductCode(code) {
    if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
      this.selectedProductsCodes.push(code);
    } else {
      this.selectedProductsCodes = [code];
    }
    this.allChecked = this.selectedProductsCodes.length === this.productCodes.length;

    this.knBrCommonService.setQuoteListSelectedProducts(this.selectedProductsCodes);
  }
  removeProductCode(code) {
    this.selectedProductsCodes.splice(this.selectedProductsCodes.indexOf(code), 1);
    this.allChecked = this.selectedProductsCodes.length === this.productCodes.length;

    this.knBrCommonService.setQuoteListSelectedProducts(this.selectedProductsCodes);
  }
  getSortLabels(): Observable<{ byCreationDate: string; byQuoteNumber: string; byDate: string; byStatus: string, byExpirationDate: string }> {
    return combineLatest([
      this.translation.translate('sorting.creationDate'),
      this.translation.translate('sorting.quoteNumber'),
      this.translation.translate('sorting.validToDate'),
      this.translation.translate('sorting.status'),
      this.translation.translate('sorting.expirationDate'),
    ]).pipe(
      map(([textCreationDate, textQuoteNumber, textByDate, textByStatus, textExpirationDate]) => {
        return {
          byCreationDate: textCreationDate,
          byQuoteNumber: textQuoteNumber,
          byDate: textExpirationDate,
          byStatus: textByStatus,
          byExpirationDate: textByDate
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrQuoteService.search(this.searchCriteria);
  }

  downloadQuotes(param1, param2, downloadFlag, fileName) {
    if (downloadFlag) {
      const data = {
        param1: param1,
        param2: param2,
        searchCriteria: this.searchCriteria,
        startDownload: true,
        fileName: fileName,
        displayName: 'Quotes'
      }
      this.knBrCommonService.downloadItems(data)
    }
  }
}
