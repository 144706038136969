<div class="price-book-up">
  <div class="modal-header d-flex align-items-center">
      <h4 class="modal-title">
          {{ 'factSheetModal.labels.title' | cxTranslate }}
      </h4>
  </div>
  <div class="modal-body float-left w-100 p-0 mt-3">
      <div class="col-md-12 float-left">
          <p>{{ 'factSheetModal.labels.headerMessage' | cxTranslate }}</p>
      </div>
      <div class="col-md-12 float-left customersearch-forms login-forms pl-3 customer-home-forms mb-4 was-validated">
          <div class="form-group mt-3 col-sm-6 pl-0 float-left mb-2 p-res-0 valid-to">
              <div class="input-group">
                  <input
                      class="form-control w-100 mb-0 dateControls ng-pristine ng-valid ng-touched"
                      [placeholder]="'factSheetModal.labels.pricingDate' | cxTranslate"
                      name="pricingDate"
                      [(ngModel)]="pricingDate"
                      (ngModelChange)="onPriceDateChange($event)"
                      ngbDatepicker
                      #pricingDatePicker="ngbDatepicker"
                      [minDate]="currentDate"
                      [readOnly]="true"
                  />
                  <span class="highlight"></span>
                  <label class="text-muted">{{ 'factSheetModal.labels.pricingDate' | cxTranslate }}</label>
                  <div class="input-group-append">
                      <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="pricingDatePicker.toggle()" type="button">
                          <i class="far fa-calendar-alt color-font header-icon-color"></i>
                      </button>
                  </div>
              </div>
              <div *ngIf="selectionError" class="price-book-msg-area">
                  {{ 'factSheetModal.labels.priceInvalidMsg' | cxTranslate }}
              </div>
          </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="handleContinueClick()">Continue</button>
          <button type="button" class="btn btn-outline-primary" (click)="handleCancelModal()">Cancel</button>
      </div>
  </div>
</div>