<div [formGroup]="caseListSearchForm">
  <div class="row mr-5 ml-5 mb-2">
  <div class="col-lg-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" placeholder="Case ID#" formControlName="svcCaseNumber"/>
      <span class="highlight"></span>
      <label class="text-muted">Case ID#</label>
    </div>
    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select  [items]="caseTypesData" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'Select Case Type'"
      clearAllText="Clear" formControlName="caseType" [bindLabel]="'name'" [bindValue]="'value'">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['name']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['name']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">Case Type</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" placeholder="Sales Order #" formControlName="orderNumber"/>
      <span class="highlight"></span>
      <label class="text-muted">Sales Order #</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" placeholder="Invoice #" formControlName="invoiceNumber"/>
      <span class="highlight"></span>
      <label class="text-muted">Invoice #</label>
    </div>
    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select  [items]="statusListData" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'invoiceSearchForm.label.status' | cxTranslate"
      clearAllText="Clear" formControlName="status" [bindLabel]="'name'" [bindValue]="'value'">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['name']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['name']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.status' | cxTranslate }}</label>
    </div>
  </div>
</div>
<div class="row mr-5 ml-5 mb-5 d-flex">
  <button class="btn btn-primary mr-3"  (click)="submitForm()">Search</button>
  <button class="btn btn-outline-primary"  (click)="resetTheForm()">Reset</button>
</div>
<div class="row">
<div class="col-lg-12 p-0" *ngIf="ticketsData">

  <div class="row counter-wrapper mr-5 ml-5" *ngIf="ticketsData && ticketsData.length ">
    <div class="col-12 pl-0 pr-4 d-flex">
      <div class="label-heading ml-4 mb-3 heading-tooltip col-lg-10 pl-0">
        View or Manage Existing Cases
        <span ngbTooltip="{{vieworManageHeadingToolTipText}}" triggers="hover" placement="right">
        <img src="assets/images/info_icon.svg" class="pb-1">
        </span>
      </div>
      <div class="customersearch-forms login-forms p-0 customer-home-forms col-lg-2">
        <div class="form-group pl-0 p-res-0">
          <ng-select class="mb-1" [items]="sortByData" [placeholder]="'Sort By'"
            [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" formControlName="sort" (change)="submitForm()">
          </ng-select>
          <span class="highlight"></span>
          <label class="text-muted">Sort By</label>
        </div>
      </div>
    </div>
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-1 font-sm-size">Case ID #</div>
        <div class="col-md-4 padding-res-0 float-left font-text-600 pl-0 font-sm-size">Case Description</div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">Case Type</div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">Created On</div>
        <div class="col-md-1 float-left font-text-600 pl-0 pr-0 font-sm-size">Last Updated</div>
        <div class="col-md-1 float-left status font-text-600 pl-0 font-sm-size">Status</div>
        <div class="col-md-2 float-left font-text-600 action-padding pl-4 pr-0 font-sm-size">Action</div>
      </div>

      <div class="col-lg-12 bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 font-weight-normal d-flex align-items-center" *ngFor="let eachTicket of ticketsData">
        <div class="col-md-1 float-left title-wrap pl-1 font-sm-size color-font font-text-400">
          <a href="javascript:void(0)" (click)="handleViewCaseClick(eachTicket)" class="cx-link">{{ eachTicket.svcCaseNumber }}</a>
        </div>
        <div class="col-md-4 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.caseDescription }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          {{eachTicket.caseType }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.createdOn }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.lastUpdated }}
        </div>
        <div class="col-md-1 status-padding title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.status }}
        </div>
        <div class="action col-md-2 title-wrap pl-0 font-sm-size color-font">
          <a href="javascript:void(0)" (click)="handleViewCaseClick(eachTicket)" class="cx-link">View</a>
          <ng-container *ngIf="eachTicket.status !== 'Closed'" > &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</ng-container>
          <a href="javascript:void(0)" class="cx-link" *ngIf="eachTicket.status !== 'Closed'" (click)="handleCaseUpdateClick(eachTicket)">Update</a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="paginate">
      <div class="col-lg-12 d-flex">
        <div class="col-6 px-0">
          <ng-container *ngIf="paginate">
            <div class="p-2 mt-2 font-text-400 font-md-size text-muted">
              Showing {{ticketsData.length}} out of {{paginate.totalResults}} Tickets
            </div>
          </ng-container>
        </div>
        <div class="col-6">
          <div class=" w-100 mt-3 mb-3">
            <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && apiCalled"
              (click)="loadMore(paginate.currentPage)" class="btn btn-primary mt-0 mr-3">
              Show More
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="alert-info p-3 rounded mt-3"
    *ngIf="ticketsData && ticketsData.length < 1 && (apiCalled)">
      No tickets available
  </div>
</div>
</div>
</div>
