<div class="col-md-12 float-left p-2" *ngIf="factSheetData$ | async as factSheetData">
  <div class="col-md-3 p-0 float-left">
    <div>
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ factSheetData?.header?.accountNumber }}</p>
    </div>
    <div>
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.address' | cxTranslate }}
      </label>
      <p class="mb-3 text-muted mb-0">
        {{ factSheetData?.header?.accountName }} <br>
        {{ factSheetData?.header?.addressInfo?.line1 }} <br>
        {{ factSheetData?.header?.addressInfo?.town }} {{ formatState(factSheetData?.header?.addressInfo?.region?.isocode) }} {{ factSheetData?.header?.addressInfo?.postalCode }} <br>
        {{ factSheetData?.header?.addressInfo?.country?.name }}
      </p>
    </div>
    <div>
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.accountPhone' | cxTranslate }}
      </label>
      <p class="mb-0 text-muted mb-0">
        {{ this.formatPhone(factSheetData?.header?.addressInfo?.phone) }}
      </p>
    </div>
  </div>
  <div class="col-md-3 p-0 float-left" *ngIf="arrayToObject(factSheetData?.header?.emails?.entry) as entries">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.orderEmail' | cxTranslate }}
      </label>
      <ng-container *ngIf="entries.hasOwnProperty('orderEmail'); else noOrderEmailValues">
        <ng-container *ngIf="splitEmails(entries['orderEmail']) as emails">
          <div *ngIf="emails.length > EMAILS_SHOW_LIMIT">
            <ng-container *ngIf="!showAllOrderEmails">
              <div class="text-muted mb-2" *ngFor="let email of emails.values">{{ email }}</div>
            </ng-container>
            <ng-container *ngIf="showAllOrderEmails">
              <div class="text-muted mb-2" *ngFor="let email of emails.values.slice(0,EMAILS_SHOW_LIMIT)">{{ email }}</div>
            </ng-container>

            <a *ngIf="showAllOrderEmails" href="javascript: void(0)" (click)="showAllOrderEmails = !showAllOrderEmails">
              {{ 'factSheetHeader.labels.showMore'| cxTranslate }}</a>
            <a *ngIf="!showAllOrderEmails" href="javascript: void(0)" (click)="showAllOrderEmails = !showAllOrderEmails">
              {{'factSheetHeader.labels.hide'| cxTranslate }}</a>
          </div>
          <div *ngIf="emails.length <= EMAILS_SHOW_LIMIT && emails.length > 0">
            <div class="mb-1" *ngFor="let email of emails.values">{{ email }}</div>
          </div>
          <div *ngIf="emails.length === 0">
            <div class="mb-1"></div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noOrderEmailValues>
        <div class="mb-1"></div>
      </ng-template>
    </div>
  </div>

  <div class="col-md-3 p-0 float-left" *ngIf="arrayToObject(factSheetData?.header?.emails?.entry) as entries">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.invoiceEmail' | cxTranslate }}
      </label>
      <ng-container *ngIf="entries.hasOwnProperty('invoiceEmail'); else noInvoiceEmailValues">
        <ng-container *ngIf="splitEmails(entries['invoiceEmail']) as emails">
          <div *ngIf="emails.length > EMAILS_SHOW_LIMIT">
            <ng-container *ngIf="!showAllInvoiceEmails">
              <div class="text-muted mb-2" *ngFor="let email of emails.values">{{ email }}</div>
            </ng-container>
            <ng-container *ngIf="showAllInvoiceEmails">
              <div class="text-muted mb-2" *ngFor="let email of emails.values.slice(0,EMAILS_SHOW_LIMIT)">{{ email }}
              </div>
            </ng-container>

            <a *ngIf="showAllInvoiceEmails" href="javascript: void(0)" (click)="showAllInvoiceEmails = !showAllInvoiceEmails">
              {{ 'factSheetHeader.labels.showMore' | cxTranslate }}</a>
            <a *ngIf="!showAllInvoiceEmails" href="javascript: void(0)" (click)="showAllInvoiceEmails = !showAllInvoiceEmails">
              {{ 'factSheetHeader.labels.hide' | cxTranslate }}</a>
          </div>
          <div *ngIf="emails.length <= EMAILS_SHOW_LIMIT && emails.length > 0">
            <div class="text-muted mb-2" *ngFor="let email of emails.values">{{ email }}</div>
          </div>
          <div *ngIf="emails.length === 0">
            <div class="mb-1"></div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noInvoiceEmailValues>
        <div class="mb-1"></div>
      </ng-template>
    </div>
  </div>
</div>

<ng-container>
  <div class="col-md-12 float-left p-0 pt-4 font-lg-size" *ngIf="brandList$ | async as brands">
    <div class="brands" *ngIf="brands.length > 0 || (factSheetData$ | async) as factSheetData">
      <div class="mr-3 font-size-17 font-text-bold">{{ 'factSheetHeader.labels.viewPricesFor' | cxTranslate }}</div>
    </div>
    <div class="sub-heading-brand">Select the respective brands to download the price book</div>
    <div class="d-flex brand-box-style">
      <div *ngFor="let key of sortBrands(brands)" class="brand-box">
        <label >
          <input class="check-box" type="checkbox" (change)="onBrandChange(key, $event.target.checked)" [checked]="selectedBrands.includes(key)" />
          <div [innerHTML]="getImage(key)" class="brand-img" ></div>
        </label>
      </div>
    </div>
    <ng-container *ngIf="selectedBrands.length">
      <div class="sub-heading-category">Select the respective category</div>
      <div class="d-flex flex-wrap category-styles">
        <div *ngFor="let category of categories">
          <label >
            <input type="checkbox" (change)="onCategoryChange(category, $event.target.checked)" [checked]="selectedCategories.includes(category)" />
            {{category}}
          </label>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="selectedCategories.length">
      <div class="d-flex justify-content-end">
        <a class="cx-link price-book-style" (click)="getPriceBookDetail()">View price book detail</a>
        <div class="float-right font-color-knbr-primary font-size font-text-600">
          <button class="font-color-knbr-primary download-links btn btn-outline-primary pt-3 download-link-btn" [disabled]="isDisabled" (click)="openCriteriaModal()">
            <i class="far fa-envelope pr-2 pl-2" aria-hidden="true"></i> {{ 'quoteCommon.label.emailMyPdf' | cxTranslate }}&nbsp;&nbsp;
          </button>
          </div>
      </div>

    </ng-container>
    <ng-container *ngIf="selectedCategories.length && priceBookClicked">
      <div class="pb-acc-styles pl-3">Price Book details</div>
      <ngb-accordion #acc="ngbAccordion"  [closeOthers]="false" activeIds="panel-1">
        <ngb-panel  class="p-0" id="panel-1">
          <ng-template ngbPanelTitle>
            <span (click)="buttonChange(this.buttonName)">
              {{ this.buttonName }}
            </span>
         </ng-template>
         <ng-template ngbPanelContent>
          <div *ngIf="factSheetData$ | async as factSheetData">
            <div class="d-flex">
              <button  *ngFor="let item of selectedBrandNamesList" class="btn btn-brand mr-2" [ngClass]="(item.id === activeBrand || item.idCA === activeBrand) ? 'btn-selected' : ''" (click)="brandChangeOnTable(item)"  >{{item.name}}</button>
            </div>
            <hr class="mt-0 ml-4">
            <div class="col-lg-12 p-0 d-flex justify-content-md-between" *ngIf="factSheetData?.priceSheetItems && factSheetData?.priceSheetItems.length">
              <div class="p-0 mt-0 mb-2 font-text-400 font-md-size">
                {{'factSheetMaterial.showingDetail' | cxTranslate : { current: (factSheetData?.priceSheetItems?.length), total: factSheetData?.pagination?.totalResults } }}
              </div>
            </div>
          </div>
          <cx-page-slot position="BottomContent"></cx-page-slot>
       </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
  </div>
</ng-container>


<ng-container class="cx-spinner" *ngIf="downloadProgress">
  <cx-spinner class="cx-dialog-popover-center-backdrop"></cx-spinner>
</ng-container>
