import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { KnBrCommonService } from '../services/kn-br-common.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from '../models/customer.model';

@Component({
  selector: 'knbr-kn-br-profile',
  templateUrl: './kn-br-profile.component.html',
  styleUrls: ['./kn-br-profile.component.scss']
})
export class KnBrProfileComponent implements OnInit {
  profileDetails;
  notificationListData: [];
  customerListData: [];
  selectedCustomer: CustomerData = null;
  subscription = new Subscription();
  profileDetailsForm: UntypedFormGroup;
  profileData$: BehaviorSubject<any> = new BehaviorSubject(null);
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;

  constructor(
    protected fb: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    public knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCommonService.getProfileDetails();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.knBrCommonService.profileData$.subscribe(res => {
        if (res) {
          this.profileDetails = res;
          this.notificationListData = res.notificationListData;
          this.customerListData = res?.customerListData;
          this.initForm();
          this.cdr.detectChanges();
        }
      })
    )

    this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
    });
  }

  initForm() {
    this.profileDetailsForm = this.fb.group({
      firstName: [{value: this.profileDetails?.firstName, disabled: true}],
      lastName: [{value: this.profileDetails?.lastName, disabled: true}],
      preferredName: [{value: this.profileDetails?.preferredName, disabled: true}],
      businessEmail: [{value: this.profileDetails?.emailId, disabled: true}],
      role: [{value: this.profileDetails?.role, disabled: true}],
      // customerGroup: [{value: this.profileDetails?.userType, disabled: true}],
      businessPhone: [{value: this.checkForCountryCode(this.profileDetails?.businessPhone), disabled: true}],
      extension: [{value: this.profileDetails?.extn, disabled: true}],
      businessCell: [{value: this.checkForCountryCode(this.profileDetails?.businessMobile), disabled: true}],
      alternateCell: [{value: this.checkForCountryCode(this.profileDetails?.alternateMobile), disabled: true}],
      mobileNumber: [{value: this.profileDetails?.mobileNumber, disabled: true}],
      // alternateMobileNumber: null,
    });
  }

  goBack() {
    this.routingService.go({ cxRoute: 'invoiceList' }, { state: { displayPersistedValues: true }});
  }

  navigate(url: string) {
    this.routingService.go(url, { state: { fromProfilePage: true }});
  }

  checkForCountryCode(phoneNumber) {
    if(phoneNumber?.includes('#')) {
      let splitPhoneByhash = phoneNumber.split('#');
      phoneNumber = splitPhoneByhash[0] + "  " + splitPhoneByhash[1];
    }
    return phoneNumber;
  }

  goTo() {
    if (this.selectedCustomer === null) {
      this.globalMessageService.add({ key: 'customer.context.required' }, GlobalMessageType.MSG_TYPE_INFO);
      this.routingService.goByUrl('/');
    } else {
      this.routingService.goByUrl('/contact-us');
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.knBrCommonService.profileData$.next(null);
    }
  }
}
