import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { CartAdapter, CartEntryAdapter } from '@spartacus/cart/base/core';
import { AuthGuard, AuthRedirectService, AuthStatePersistenceService, ConfigModule, CxDatePipe } from '@spartacus/core';
import { OrderHistoryAdapter } from '@spartacus/order/core';
import { B2BUserService } from '@spartacus/organization/administration/core';
import { PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { SidebarModule } from 'ng-sidebar';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KnBrI18nModule } from './common/i18n/i18n.module';
import { CustomerIdMapHttpInterceptor } from './common/interceptors/customer-id-map-http-config.interceptor';
import { KnBrBackButtonModule } from './common/kn-br-back-button/kn-br-back-button.module';
import { KnBrBannerModule } from './common/kn-br-banner/kn-br-banner.module';
import { KnBrBreadCrumbModule } from './common/kn-br-bread-crumb/kn-br-bread-crumb.module';
import { KnBrConfirmDialogModule } from './common/kn-br-confirm-dialog/kn-br-confirm-dialog.module';
import { KnBrCustomersTitleModule } from './common/kn-br-customer-title/kn-br-customer-title.module';
import { KnBrDocViewerComponent } from './common/kn-br-doc-viewer/kn-br-doc-viewer.component';
import { KnBrFooterModule } from './common/kn-br-footer/kn-br-footer.module';
import { KnBrLayoutConfigModule } from './common/kn-br-layout-config/kn-br-layout-config.module';
import { KnBrMessageDialogModule } from './common/kn-br-message-dialog/kn-br-message-dialog.module';
import { KnBrMetaResolverModule } from './common/kn-br-meta-resolvers/kn-br-meta-resolver.module';
import { KnBrMiniCartModule } from './common/kn-br-mini-cart/kn-br-mini-cart.module';
import { KnBrOutletModule } from './common/kn-br-outlet/kn-br-outlet.module';
// import { KnBrParagraphModule } from './common/kn-br-paragraph/kn-br-paragraph.module';
import { KnBrProductCarouselModule } from './common/kn-br-product-carousel/kn-br-product-carousel.module';
import { knBrDefaultAsmLayoutConfig } from './kn-br-asm/kn-br-asm-layout-config';
import { KnBrAsmModule } from './kn-br-asm/kn-br-asm.module';
import { KnBrCheckoutModule } from './kn-br-checkout/kn-br-checkout.module';
import { KnBrCustomer360Module } from './kn-br-customer360/kn-br-customer360.module';
import { KnBrFactSheetHeaderModule } from './kn-br-customer360/kn-br-fact-sheet/kn-br-fact-sheet-header/kn-br-fact-sheet-header.module';
import { KnBrDraftOrderModule } from './kn-br-draft-order/kn-br-draft-order.module';
import { KnBrForgotPasswordModule } from './kn-br-forgot-password/kn-br-forgot-password.module';
import { KnBrHomeModule } from './kn-br-home/kn-br-home.module';
import { KnBrLoginApproverModule } from './kn-br-login-approver/kn-br-login-approver.module';
import { KnBrLoginFormModule } from './kn-br-login-form/kn-br-login-form.module';
import { KnBrNacModule } from './kn-br-nac/kn-br-nac.module';
import { KnBrCategoryNavigationModule } from './kn-br-navigation/kn-br-category-navigation/kn-br-category-navigation.module';
import { KnBrNavigationModule } from './kn-br-navigation/kn-br-navigation.module';
import { KnBrOrderConfirmationModule } from './kn-br-order-confirmation/kn-br-order-confirmation.module';
import { KnBrOrderToolsModule } from './kn-br-order-tools/kn-br-order-tools.module';
import { KnBrOrganizationUsersModule } from './kn-br-organization-users/kn-br-organization-users.module';
import { KnBrProductModule } from './kn-br-product/kn-br-product.module';
import { KnBrQuickLinksModule } from './kn-br-quick-links/kn-br-quick-links.module';
import { KnBrQuoteModule } from './kn-br-quote/kn-br-quote.module';
import { KnBrResetPasswordModule } from './kn-br-reset-password/kn-br-reset-password.module';
import { KnBrServiceTicketsModule } from './kn-br-service-tickets/kn-br-service-tickets.module';
import { KnBrUpdateProfileModule } from './kn-br-update-profile/kn-br-update-profile.module';
import { KnBrUserRegisterModule } from './kn-br-user-register/kn-br-user-register.module';
import { KnBrAuthRedirectService } from './services/kn-br-auth-redirect.service';
import { KnBrAuthStatePersistenceService } from './services/kn-br-auth-state-persistence.service';
import { KnBrB2BUserService } from './services/kn-br-b2b-user.service';
import { KnBrCsAgentAuthService } from './services/kn-br-csagent-auth.service';
import { KnBrOccCartEntryAdapter } from './services/kn-br-occ-cart-entry.adapter';
import { KnBrOccCartAdapter } from './services/kn-br-occ-cart.adapter';
import { KnBrOccUserOrderAdapter } from './services/kn-br-occ-user-order.adapter';
import { KnBrAuthGuard } from './shared/guards/kn-br-auth.guard';
import { MomentDateFormatter } from './shared/moment-date.formatter';
import { SpartacusModule } from './spartacus/spartacus.module';
import { KnBrAddressStateModule } from './store/kn-br-address/kn-br-address.state.module';
import { KnBrCartContextStateModule } from './store/kn-br-cart-context/kn-br-cart-context.module';
import { KnBrCartStateModule } from './store/kn-br-cart/kn-br-cart-state.module';
import { KnBrCustomerContextStateModule } from './store/kn-br-customer-context/kn-br-customer-context.module';
import { KnBrCustomerStateModule } from './store/kn-br-customer/kn-br-customer.state.module';
import { KnBrDraftOrdersCommentsStateModule } from './store/kn-br-draft-order-comments/kn-br-draft-orders-comments.state.module';
import { KnBrDraftOrdersStateModule } from './store/kn-br-draft-orders/kn-br-draft-orders.state.module';
import { KnBrFactSheetStateModule } from './store/kn-br-fact-sheet/kn-br-fact-sheet.state.module';
import { KnBrInvoiceStateModule } from './store/kn-br-invoice/kn-br-invoice.state.module';
import { KnBrOrderHistoryStateModule } from './store/kn-br-order-history/kn-br-order-history.state.module';
import { KnBrOrderReturnRequestStateModule } from './store/kn-br-order-return-request/kn-br-order-return-request.state.module';
import { KnBrOrderReturnStateModule } from './store/kn-br-order-return/kn-br-order-return.state.module';
import { KnBrQuoteContextStateModule } from './store/kn-br-quote-context/kn-br-quote-context.module';
import { KnBrQuoteEntryStateModule } from './store/kn-br-quote-entry/kn-br-quote-entry.module';
import { KnBrQuoteReferenceStateModule } from './store/kn-br-quote-reference/kn-br-quote-reference.module';
import { KnBrQuotesCommentsStateModule } from './store/kn-br-quotes-comments/kn-br-quotes-comments.state.module';
import { KnBrQuotesStateModule } from './store/kn-br-quotes/kn-br-quotes.state.module';
import { KnBrSalesOrgStateModule } from './store/kn-br-salesorg/kn-br-salesorg.module';
import { KnBrSpinnerStateModule } from './store/kn-br-spinner/kn-br-spinner-state.module';
import { KnbrSsoModule } from './knbr-sso/knbr-sso.module';
import { KnBrSpotlightModule } from './kn-br-spotlight/kn-br-spotlight.module';
import { KnBrTemplateModule } from './kn-br-template/kn-br-template.module';
import { KnBrContactModule } from './kn-br-contact/kn-br-contact.module';
import { KnBrLogoutModule } from './kn-br-logout/kn-br-logout.module';
import { KnBrCaseCreateModule } from './kn-br-case-create/kn-br-case-create.module';
import { KnBrCaseListingModule } from './kn-br-case-listing/kn-br-case-listing.module';
import { KnBrCaseDetailModule } from './kn-br-case-detail/kn-br-case-detail.module';
import { KnBrProfileModule } from './kn-br-profile/kn-br-profile.module';
import { KnBrShippingModule } from './kn-br-shipping/kn-br-shipping.module';
import { KnBrShippingListModule } from './kn-br-shipping/kn-br-shipping-list/kn-br-shipping-list.module';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: CustomerIdMapHttpInterceptor,
      multi: true,
    },
    {
      provide: CartAdapter,
      useClass: KnBrOccCartAdapter,
    },
    {
      provide: CartEntryAdapter,
      useClass: KnBrOccCartEntryAdapter,
    },
    {
      provide: OrderHistoryAdapter,
      useClass: KnBrOccUserOrderAdapter,
    },
    {
      provide: AuthRedirectService,
      useClass: KnBrAuthRedirectService,
    },
    {
      provide: CsAgentAuthService,
      useClass: KnBrCsAgentAuthService,
    },
    {
      provide: B2BUserService,
      useClass: KnBrB2BUserService,
    },
    {
      provide: AuthStatePersistenceService,
      useClass: KnBrAuthStatePersistenceService,
    },
    {
      provide: AuthGuard,
      useClass: KnBrAuthGuard,
    },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    CxDatePipe,
    NgbActiveModal,
  ],
  declarations: [AppComponent, KnBrDocViewerComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    KnBrAsmModule,
    ConfigModule.withConfig(knBrDefaultAsmLayoutConfig),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    KnBrOutletModule,
    PageSlotModule,
    SpinnerModule,
    KnBrBannerModule,
    KnBrLoginFormModule,
    KnBrForgotPasswordModule,
    KnBrFooterModule,
    KnBrBreadCrumbModule,
    // KnBrParagraphModule,
    KnBrCustomersTitleModule,
    KnBrLayoutConfigModule,
    KnBrHomeModule,
    SidebarModule.forRoot(),
    KnBrNavigationModule,
    KnBrI18nModule,
    KnBrCategoryNavigationModule,
    KnBrProductModule,
    KnBrDraftOrderModule,
    KnBrQuoteModule,
    KnBrMiniCartModule,
    KnBrCheckoutModule,
    KnBrConfirmDialogModule,
    KnBrMessageDialogModule,
    KnBrCartStateModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    KnBrCustomerStateModule,
    KnBrCustomerContextStateModule,
    KnBrDraftOrdersStateModule,
    KnBrQuotesStateModule,
    KnBrCartContextStateModule,
    KnBrQuoteContextStateModule,
    KnBrQuoteEntryStateModule,
    KnBrResetPasswordModule,
    KnBrQuotesCommentsStateModule,
    KnBrDraftOrdersCommentsStateModule,
    KnBrQuoteReferenceStateModule,
    KnBrLoginApproverModule,
    KnBrMetaResolverModule,
    KnBrProductCarouselModule,
    NgxDocViewerModule,
    KnBrCustomer360Module,
    KnBrInvoiceStateModule,
    KnBrOrderHistoryStateModule,
    KnBrSalesOrgStateModule,
    KnBrOrderReturnStateModule,
    KnBrOrderReturnRequestStateModule,
    KnBrOrderToolsModule,
    KnBrServiceTicketsModule,
    KnBrAddressStateModule,
    KnBrUpdateProfileModule,
    KnBrUserRegisterModule,
    KnBrNacModule,
    KnBrQuickLinksModule,
    KnBrOrganizationUsersModule,
    KnBrBackButtonModule,
    KnBrOrderConfirmationModule,
    KnBrFactSheetHeaderModule,
    KnBrFactSheetStateModule,
    KnBrSpotlightModule,
    KnBrCaseCreateModule,
    KnBrCaseListingModule,
    KnBrCaseDetailModule,
    ConfigModule.withConfig({
      pagination: {
        addPrevious: true,
        addNext: true,
        addDots: true,
      },
    }),
    KnBrSpinnerStateModule,
    KnbrSsoModule,
    KnBrTemplateModule,
    KnBrContactModule,
    KnBrLogoutModule,
    KnBrProfileModule,
    KnBrShippingModule,
    KnBrShippingListModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
