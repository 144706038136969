import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule, I18nModule],
  selector: 'knbr-price-book-query-modal',
  templateUrl: './price-book-query-modal.component.html',
  styleUrls: ['./price-book-query-modal.component.scss'],
})

export class PriceBookQueryModalComponent implements OnInit {
  now = new Date();
  
  currentDate = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  pricingDate = this.currentDate;
  selectionError = false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

   /**
   * Handles modal continue event. Validates selected pricing date and, if valid, closes modal passing
   * parsed date to caller. Invalid dates/error will cause modal to remain open with error message
   * displayed.
   */
  handleContinueClick(): void {
    this.selectionError = !this.isValidDate();
    if (!this.selectionError) {
      this.activeModal.close(this.getFormattedDate());
    }
  }

  /**
   * Handles modal close event (cancelled). Simply closes the modal passing back false value indicating
   * user cancelled. 
   */
  handleCancelModal() {
    this.activeModal.close(false);
  }

  /**
   * Handle date change events. Validates newly selected date.
   * 
   * @param event   date change event 
   */
  onPriceDateChange(event) {
    this.selectionError = !this.isValidDate();
  }

  /**
   * Determines if the currently selected pricing date is valid. The object that holds the date value must be an object 
   * type with members 'year', 'month', and 'day' all numeric types.
   * 
   * @returns true if selected date is valid or false otherwise 
   */
  isValidDate() {
    let valid = false;

    if (this.pricingDate != null) {
      if (this.pricingDate instanceof Object) {
        valid = (typeof this.pricingDate.year === 'number') && 
        (typeof this.pricingDate.month === 'number') && 
        (typeof this.pricingDate.day === 'number');
      }
    }

    return valid;
  }

  /**
   * Formats date in YYYYMMDD.
   * 
   * @returns formatted date 
   */
  getFormattedDate() {
    return this.pricingDate.year +
      ('0' + this.pricingDate.month).slice(-2) +
      ('0' + this.pricingDate.day).slice(-2)
  }
}
