import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class KnBrDateHelper {
  constructor() {}
  formatDate(date: any) {
    const ngbDate = JSON.parse(JSON.stringify(date));
    if (ngbDate && ngbDate.year) {
      return `${ngbDate.year.toString()}${this.fixDigit(ngbDate.month.toString())}${this.fixDigit(
        ngbDate.day.toString()
      )}`;
    }
    return '';
  }
  shipformatDate(date: any) {
    const ngbDate = JSON.parse(JSON.stringify(date));
    if (ngbDate && ngbDate.year) {
      return `${this.fixDigit(ngbDate.month)}/${this.fixDigit(ngbDate.day)}/${ngbDate.year}`;
    }
    return '';
  }
  getDate(value: string) {
    if (!value) {
      return null;
    }
    const mdt = moment(value.trim(), ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD']);
    return mdt.isValid()
      ? {
          day: mdt.date(),
          month: mdt.month() + 1,
          year: mdt.year(),
        }
      : null;
  }

  private fixDigit(val) {
    return val.toString().length === 1 ? '0' + val : val;
  }
}
