import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { Statuses } from 'src/app/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-shipping-list',
  templateUrl: './kn-br-shipping-list.component.html',
  styleUrls: ['./kn-br-shipping-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrShippingListComponent implements OnInit, OnDestroy {
  shippingList$: Observable<any> = this.knbrcommonservice.loadShippingData$();
  shippingPointByIdData$: Observable<any> = this.knbrcommonservice.loadShippingPointsByIdData$();
  shipmentTypeList$: Observable<Statuses[]> = this.knbrcommonservice.loadShipmentTypeList$();
  brandSalesShipToList$: Observable<any> = this.knbrcommonservice.loadBrandSalesShipToList$();
  index: any = 0
  show = false;
  payload;
  totalItems = [] ;
  pageSize = 50;
  currentItems = [];
  customerNumber = '';
  currentPage = 0;
  value
  name
  brandList = [];
  subscription = new Subscription();

  constructor(
    private knbrcommonservice: KnBrCommonService,
    protected routingService: RoutingService,
  ) {}

  ngOnInit() {
    this.knbrcommonservice.getShippingPointsByIds();
    this.subscription.add(
      this.shippingList$.subscribe(res => {
        if (res) {
          this.totalItems = res;
          this.currentItems = res.slice(0, 50);
          this.currentPage = 1;
        } else {
          this.totalItems = [];
        }
      })
    )

    this.subscription.add(
      this.brandSalesShipToList$.subscribe((res: any) => {
        this.brandList = [];
        if (res && res.brand) {
          this.brandList = res.brand
        }
      })
    )
  }

  loadMore() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.currentItems = this.totalItems.slice(0, endIndex);
    this.currentPage++;
  }

  downloadShipment() {
    const payload = JSON.parse(window.sessionStorage.getItem('shipmentSearch'));
    this.knbrcommonservice.multiDownload(true, 'Shipment_tracking', payload);
  }

  downloadFile(shippingItem) {
    const shipmentDetails = {
      documentName: 'Shipment',
      documentNumber: shippingItem?.deliveryId,
      documentReference: 'V2'
    };
    this.knbrcommonservice.downloadSinglePDF(shipmentDetails);
  }

  showHide($event: NgbPanelChangeEvent, acc) {
    if (!acc.isExpanded($event.panelId)) {
      const idx = $event.panelId.slice(-1)
      this.show = true;
      this.index = idx;
    } else {
      this.show = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
