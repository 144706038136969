<ng-container *ngIf="selectedCustomer">
  <div class="col-lg-12 float-left p-3 border border-bottom-0 bg-light-gray">
    <div class="w-100">
      <div class="w-100 customersearch-title">
        {{ 'invoiceSearchForm.header.openInvoice' | cxTranslate }}
      </div>
    </div>
  </div>
  <div class="col-lg-12 float-left p-3 border">
    <form class="customersearch-forms login-forms p-0 w-100" [formGroup]="invoiceSearchForm" (ngSubmit)="submitForm()">
      <div class="col-12 p-0 float-left d-flex align-items-center">
        <div class="col-md-2 pl-0 form-group float-left valid-to mt-3 mb-3">
          <div class="input-group">
            <input class="form-control w-100 mb-0" [placeholder]="'invoiceSearchForm.label.from' | cxTranslate"
              name="validFrom" ngbDatepicker #validFrom="ngbDatepicker" formControlName="startDate" [maxDate]="maxStartDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'invoiceSearchForm.label.from' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validFrom.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-2 pl-0 form-group float-left valid-to mt-3 mb-3">
          <div class="input-group">
            <input class="form-control w-100 mb-0" [placeholder]="'invoiceSearchForm.label.to' | cxTranslate"
              name="validTo" [minDate]="invoiceSearchForm.get('startDate').value" ngbDatepicker #validTo="ngbDatepicker"
              formControlName="endDate" [maxDate]="maxEndDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'invoiceSearchForm.label.to' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validTo.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="btn btn-primary col-md-2">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
    </form>
    <div class="col-lg-12 customer-search bg-white p-0 float-left" *ngIf="invoiceResult$ | async as invoiceResult">
      <div class="customersearch-list-table mt-3 float-left col-lg-12 p-0"
        *ngIf="invoiceResult && invoiceResult.length && !(isLoader$ | async)">
        <div class="customersearch-list-titles border-top border-bottom float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'invoiceResult.header.documentDate' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size pl-4">
            {{ 'invoiceResult.header.doc' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'invoiceResult.header.docType' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'invoiceResult.header.dueDate' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'invoiceResult.widgetHeader.purchaseOrder' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'invoiceResult.header.balance' | cxTranslate }}
          </div>
        </div>
        <div class="bg-white customersearch-list-titles table-content border-bottom float-left w-100 p-2 font-weight-normal d-flex align-items-center
          " (click)="downloadFile(invoice)" *ngFor="let invoice of invoiceResult">
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'invoiceResult.header.documentDate' | cxTranslate }}:</span>
            {{ invoice.date | date:"MM/dd/YYYY" }}
          </div>
          <div class="col-md-2 text-align-center title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'invoiceResult.header.document' | cxTranslate }}:</span>
            {{ invoice.documentNumber }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'invoiceResult.header.documentType' | cxTranslate }}:</span>
            {{ invoice.documentType?.name }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'invoiceResult.header.dueDate' | cxTranslate }}:</span>
            {{ invoice?.knbrDueDate }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'invoiceResult.widgetHeader.purchaseOrder' | cxTranslate }}:</span>
            {{ invoice.purchaseOrder }}
          </div>
          <div class="col-md-1 text-align-right title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'invoiceResult.header.balance' | cxTranslate }}:</span>
            {{ invoice.formattedAmount }}
          </div>
          <!-- <div class="col-md-1 text-center float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'invoiceResult.header.attachment' | cxTranslate }}:</span>
            <a target="_blank" href="{{ invoice?.documentMedia?.downloadURL }}"
              ><i class="far fa-file-pdf" aria-hidden="true"></i
            ></a>
          </div> -->
        </div>
      </div>
      <div class="alert-info p-3 rounded" *ngIf="invoiceResult && invoiceResult.length < 1 && !(isLoader$ | async)">
        {{ 'invoiceResult.nodataMsg' | cxTranslate }}
      </div>
      <ng-container *ngIf="paginate$ | async as pagination">
        <div *ngIf="pagination.totalPages > 1" class="col-lg-12 p-0 float-left mt-3">
          <cx-pagination [pagination]="pagination" (viewPageEvent)="pageChange($event)"></cx-pagination>
        </div>
      </ng-container>

      <div class="col-lg-12 p-0 float-left mt-4" *ngIf="invoiceResult && invoiceResult.length && !(isLoader$ | async)">
        <a [routerLink]="{ cxRoute: 'invoiceList' } | cxUrl">{{ 'orderReturnResult.detailedSearch' | cxTranslate }}</a>
      </div>

      <div *ngIf="(isLoader$ | async) || !(invoiceResult$ | async)">
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </div>
</ng-container>
