import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, RoutingConfig } from '@spartacus/core';
import { KnBrInvoiceListSearchModule } from './kn-br-invoice-list-search/kn-br-invoice-list-search.module';
import { KnBrInvoiceListSearchResultModule } from './kn-br-invoice-list-seach-result/kn-br-invoice-list-search-result.module';
import { KnBrInvoiceAccountSummaryModule } from './kn-br-invoice-account-summary/kn-br-invoice-account-summary.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrInvoiceAccountSummaryModule,
    KnBrInvoiceListSearchModule,
    KnBrInvoiceListSearchResultModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          invoiceList: {
            paths: ['customer-360/invoice-list'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrInvoiceListModule {}
