<div class="m-5 pt-5">
  <div class="font-text-bold ml-3 mb-4 heading-tooltip">Create New Case <span ngbTooltip="{{createcaseToolTipText}}" triggers="hover" placement="right">
      <img src="assets/images/info_icon.svg" class="pb-1">
      </span>
  </div>
  <div [formGroup]="helperForm" class="d-flex flex-wrap col-lg-12 ml-3">
    <div class="d-flex col-lg-3 mt-2">
      <div class="mr-5 pr-5">
        <input
        class="form-check-input"
        type="radio"
        name="caseOptionToCreate"
        formControlName="caseOptionToCreate"
        value="salesOrder"
      />
      <label class="form-check-label font-sm-size mt-1" for="Custom-FeatureNbenefit">Sales Order #</label>
      </div>
      <div>
        <input
        class="form-check-input"
        type="radio"
        name="caseOptionToCreate"
        formControlName="caseOptionToCreate"
        value="invoice"
      />
      <label class="form-check-label font-sm-size mt-1" for="Custom-FeatureNbenefit">Invoice #</label>
      </div>
    </div>

    <div class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="getPlaceHolderValue()" formControlName="salesOrInvoiceNumber"
      />
      <span class="highlight"></span>
      <div *ngIf="submitted && helperForm.get('salesOrInvoiceNumber') && helperForm.get('salesOrInvoiceNumber').errors">
        <small class="text-danger">
          This field is required
        </small>
      </div>
    </div>
    <div>
      <button type="submit" class="btn btn-primary mr-3" (click)="submitForm()" [disabled]="isRequiredFieldsFilled()">Search</button>
      <button class="btn btn-outline-primary"  (click)="resetTheForm()">Reset</button>
    </div>
  </div>
  <div class="float-left col-12">
    <hr class="mt-3 col-lg-12 float-left">
  </div>
</div>
